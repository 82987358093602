import CustomConfig from '../custom-config'
const ApiPath = CustomConfig.apiPath1

// 前缀 /api/v1/ 不写
const UPLOAD_SIGN_PIC = `${ApiPath}/systems/upload/file` // 上传签名图片
const ARTICLE = `articles` // 文章

/// 查询线下预约记录
const AUTO_RESERVATION = 'http://fpscp.1ekj.com:9567/reservation'

/// 社圈相关接口
const POSTERS = 'posters' // 查询/新增/修改/删除广告列表
const POSTERS_SHOW = 'posters/:id/show' // 上架
const POSTERS_PULL = 'posters/:id/pull' // 下架
const SENSITIVES = 'sensitives' // 查询敏感词列表
const SENSITIVES_LISTS = 'sensitives/lists/codes' // 查询敏感词类型编码表
const SENSITIVES_ENABLE = 'sensitives/:id/enable' // 启用
const SENSITIVES_DISABLE = 'sensitives/:id/disable' // 禁用
const TOPICS = 'topics' // 话题管理
const TOPICS_ENABLE = 'topics/:id/enable'
const TOPICS_DISABLE = 'topics/:id/disable'
const LOCATIONS = 'locations'
const REPORTS = 'reports' // 举报管理
const ARTICLES = 'articles' // 文章管理
const ARTICLES_PUSH = 'articles/:id/push' // 启用
const ARTICLES_PULL = 'articles/:id/pull' // 禁用
const ARTICLES_RECOMMENDS = 'articles/:id/recommends' // 推荐
const TRENDS = 'trends' // 动态管理
const TRENDS_REPLY = 'trends/:ids/reply' // 动态审核
const TRENDS_RECOMMENDS = 'trends/:id/recommends' // 推荐
const TRENDS_REPORTS = 'trends/:id/reports' // 查看被举报原因
const REPORTS_USER_INFO = 'reports/list_users/infos'
const POST_FORBIDS = 'forbids' // 封号
const PUT_FORBIDS = 'forbids/:id' // 解封
// 视频图片上传接口
// 线上环境
const POST_UPLOAD_FILE = 'http://fpscp.1ekj.com/social/mobile/v1/attachments'
// const POST_UPLOAD_FILE = `http://fpscp.1ekj.com:4000/mobile/v1/attachments`
// 测试环境
// const POST_UPLOAD_FILE = `http://health.1ekj.com:4000/mobile/v1/attachments`
// 本地测试环境
// const POST_UPLOAD_FILE = `http://192.168.30.212:3003/mobile/v1/attachments`

/* 运营管理系统接口 */
// 不用添加 api/v1/前缀
const PERMISSIONS = 'powers' // 通过jwt获取用户权限 加id获取详细
const DEPARTMENTS = 'departments' // 获取部门
const OM_EMPLOYEES = 'employes' // 获取员工
const ROLES = 'roles' // 获取角色列表
const USERS = 'users' // 获取用户
const USER_BY_JWT = USERS + '/tokens/jwt' // 根据jwt获取用户信息
const PWD_LOGIN = `${USERS}/sign_in/passwds` // 密码登录
const SMS_LOGIN = `${USERS}/sign_in/sms` // 验证码登录
const ORGS_LIST = 'orgs'
const ORG_EMPLOYEES = '/orgs/:org_id/employes' // 机构成员
const ORG_TEAMS = '/orgs/:org_id/teams' // 机构团队
const POST_FILE = 'attachments' // 文件上传
const NOTES = 'notes' // 获取短信验证码
const ORGS_ISADMIN_PHONE = '/orgs/administers/confirm'

/* 爬虫系统接口 */
const SPIDER_TASKS = 'api/spider/tasks'
const SPIDER_TASK = 'api/spider/task'
const SPIDER_TEMPLATES = 'api/spider/templates'
const SPIDER_DATA = 'api/spider/data'
const SPIDER_DATA_AUDIT = 'api/spider/data/audit'

/* 心康2.0接口 */
const SPROJECT_LIST = '/sproject/list'
const BIND_PROJECT = '/org/bindProject'
const ORG_PROJECT = '/org/project/:org_id'

export {
  // 最新api 其他待删除
  UPLOAD_SIGN_PIC,
  ARTICLE,
  AUTO_RESERVATION,
  // 社圈
  // 广告列表
  POSTERS,
  POSTERS_SHOW, // 上架广告
  POSTERS_PULL, // 下架广告
  // 敏感词列表
  SENSITIVES,
  SENSITIVES_LISTS,
  SENSITIVES_DISABLE, // 禁用
  SENSITIVES_ENABLE, // 启用
  // 话题管理
  TOPICS,
  TOPICS_ENABLE,
  TOPICS_DISABLE,
  LOCATIONS, // 所属位置查询
  // 举报管理
  REPORTS,
  POST_FORBIDS, // 封号
  PUT_FORBIDS, // 解封
  // 文章管理
  ARTICLES,
  ARTICLES_PUSH,
  ARTICLES_PULL,
  ARTICLES_RECOMMENDS,
  // 动态管理
  TRENDS,
  TRENDS_REPLY,
  TRENDS_RECOMMENDS,
  TRENDS_REPORTS,
  REPORTS_USER_INFO,
  POST_UPLOAD_FILE, // 文件上传
  /* 运营管理系统 */
  ROLES,
  DEPARTMENTS,
  PWD_LOGIN,
  PERMISSIONS,
  OM_EMPLOYEES,
  USERS,
  USER_BY_JWT,
  SMS_LOGIN,
  ORGS_LIST,
  POST_FILE,
  NOTES,
  ORG_EMPLOYEES,
  ORG_TEAMS,
  ORGS_ISADMIN_PHONE,
  // 爬虫管理
  SPIDER_TASKS,
  SPIDER_TEMPLATES,
  SPIDER_TASK,
  SPIDER_DATA,
  SPIDER_DATA_AUDIT,

  // 心康2.0
  SPROJECT_LIST,
  BIND_PROJECT,
  ORG_PROJECT
}
