Number.prototype.toFixed = function (this: number, precision: number) {
  if (precision < 0 && precision > 100) {
    throw new Error('toFixed() digits argument must be between 0 and 100')
  }
  // precision存在且是一个有限的数，走这里
  if (precision && isFinite(this)) {
    let p: Array<string> = (this.toString() + 'e').split('e')
    let rNo: number = Math.round(+`${p[0]}e${+p[1] + precision}`)
    p = (rNo.toString() + 'e').split('e')
    rNo = +(p[0] + 'e' + (+p[1] - precision))
    if (rNo === (~~rNo)) {
      return rNo + '.' + Array(precision + 1).join('0')
    }
    return (rNo + Array(precision + 1).join('0')).slice(0, (rNo + '').indexOf('.') + precision + 1)
  }
  // precision = 0 或者 不是一个有限的数，走这里
  return Math.round(this).toString()
}
