import PurviewCodes from '@/utils/purview-codes'

const isTest: boolean = process.env.VUE_APP_ENV === 'test' // 是否是测试环境
// const isProduction: boolean = process.env.NODE_ENV === 'production' && !isTest // 开发模式
const isProduction: boolean = process.env.NODE_ENV === 'production' && process.env.VUE_APP_ENV === 'production' // 生产模式
const basePath = isProduction ? '/' : isTest ? '/oms/' : '/'
// 请求路径前缀
const apiPath = '/api/v1'
const apiPath1 = '/doctor/api/v1'
const routeMode: any = isProduction ? 'history' : isTest ? 'history' : 'hash'

/* 正式地址 */
// 正式环境地址
const PROD_BASE_PATH = 'http://fpscp.1ekj.com'
// 社圈地址
const COMMUNITY_PROD_PATH = `${PROD_BASE_PATH}/social`
// 客服系统
const CSC_PATH_PROD = `${PROD_BASE_PATH}/csc/?userId=888888&userType=3&nikeName=%E5%AE%98%E6%96%B9%E5%AE%A2%E6%9C%8D&pic=`
// 家医
const FPSCP_BASE_PRO_PATH = `${PROD_BASE_PATH}`
// 商家运营后台
const BOSS_PROD_PATH = 'https://newshop.1ekj.com/admin/'

// 商家后台
const SUPPLIER_PROD_PATH = 'https://supplier.1ekj.com/'
// 运营地址
const OM_PROD_PATH = `${PROD_BASE_PATH}/administer`
// 爬虫地址
const SPIDER_PROD_PATH = 'http://222.85.199.17:7001/' // 后端本地地址
/************/

/* 测试地址 */
// 测试环境地址
// const TEST_BASE_PATH = 'http://health.1ekj.com'
const TEST_BASE_PATH = 'http://fpscp.1ekj.com'
// 社圈地址
const COMMUNITY_TEST_PATH = `${TEST_BASE_PATH}/social`
// 客服系统
const CSC_PATH_TEST = `${TEST_BASE_PATH}/csc/?userId=888888&userType=3&nikeName=%E5%AE%98%E6%96%B9%E5%AE%A2%E6%9C%8D&pic=`
// 运营地址
const OM_TEST_PATH = `${TEST_BASE_PATH}/administer`
// 家医
const FPSCP_BASE_PATH = TEST_BASE_PATH
// 商成运营后台
const BOSS_TEST_PATH = 'http://172.168.10.88:2002/'
// 商家后台
const SUPPLIER_TEST_PATH = 'http://172.168.10.88:3002/'
// 爬虫地址
const SPIDER_TEST_PATH = 'http://172.168.10.88:7001' // 后端本地地址
/***************/

/* 后台访问路径 */
const COMMUNITY_PATH = isProduction ? COMMUNITY_PROD_PATH : isTest ? COMMUNITY_TEST_PATH : `/community` // 生产环境
// const COMMUNITY_PATH = process.env.NODE_ENV === 'development' ? COMMUNITY_PROD_PATH : isTest ? COMMUNITY_TEST_PATH : `/community` // 开发环境
// 商成运营后台
const BOSS_PATH = isProduction ? BOSS_PROD_PATH : BOSS_TEST_PATH // 生产环境
// const BOSS_PATH = process.env.NODE_ENV === 'development' ? BOSS_PROD_PATH : BOSS_TEST_PATH // 开发环境
// 商家后台
const SUPPLIER_PATH = isProduction ? SUPPLIER_PROD_PATH : SUPPLIER_TEST_PATH
const CSC_PATH = isProduction ? CSC_PATH_PROD : CSC_PATH_TEST

const OM_PATH = isProduction ? OM_PROD_PATH : isTest ? OM_TEST_PATH : `/om` // 生产环境
// const OM_PATH = process.env.NODE_ENV === 'development' ? OM_PROD_PATH : isTest ? OM_TEST_PATH : `/om` // 开发环境
const SPIDER_PATH = isProduction ? SPIDER_PROD_PATH : SPIDER_TEST_PATH // 后端本地地址
const fpscpV2Path = 'http://yykjapp.1ekj.com' // 心康2.0
/*******************/

/* 子系统跳转地址 */
// 运营
const OM_ADDR = basePath
// 家医
const FPSCP_ADDR = (() => {
    switch (true) {
        case isProduction:
            return `${FPSCP_BASE_PRO_PATH}/fpscp?id=[id]&jwt=[jwt]`
        case isTest:
            return `${FPSCP_BASE_PATH}/fpscp?id=[id]&jwt=[jwt]`
        default:
            return `${FPSCP_BASE_PRO_PATH}/fpscp?id=[id]&jwt=[jwt]`
            // return 'http://fpscp.1ekj.com?id=[id]&jwt=[jwt]'
    }
})()
// 健康小屋
const HLH_ADDR = (() => {
    switch (true) {
        case isProduction:
            return `${FPSCP_BASE_PRO_PATH}/fpscp/recoverCenter/course?id=[id]&jwt=[jwt]`
        case isTest:
            return `${FPSCP_BASE_PATH}/fpscp/recoverCenter/course?id=[id]&jwt=[jwt]`
        default:
            return 'http://localhost:8081/#/recoverCenter/course?id=[id]&jwt=[jwt]'
    }
})()
// 农商行项目
const NSB_ADDR = 'https://rcb.1ekj.com/management/page#/imgs-list'
/*********************/

// 模块入口配置
const entries = [{
    icon: 'om-icon',
    path: OM_ADDR, // 访问路径
    name: '运营管理',
    code: PurviewCodes.MODULE_OPERATION, // 权限编码/名称
}, {
    icon: 'hlc-icon',
    path: FPSCP_ADDR, // 访问路径
    name: '家医业务',
    code: PurviewCodes.MODULE_HOME_DOCTOR, // 权限编码/名称
}, {
    icon: 'hlh-icon',
    path: HLH_ADDR, // 访问路径
    name: '健康小屋',
    code: PurviewCodes.MODULE_HLEATH_HOUSE, // 权限编码/名称
}, {
    icon: 'nsb-icon',
    path: NSB_ADDR, // 访问路径
    name: '农商行',
    code: PurviewCodes.MODULE_NSB, // 权限编码/名称
}]

const SystemName = '健康生活圈管理后台'

export default {
    defaultPath: basePath, // 获取完登录信息后的默认跳转路由
    basePath, // 基本地址
    apiPath, // 接口地址
    apiPath1, // 接口地址
    routeMode, // 路由模式
    homePath: basePath, // 右上角home图标指向的路径
    COMMUNITY_PATH, // 社圈路径
    bossPath: BOSS_PATH, // 商家后台链接
    entries, // 模块入口
    OM_PATH, // 运营管理后台地址
    SPIDER_PATH, // 爬虫
    isProduction,
    CSC_PATH,
    SystemName,
    SUPPLIER_PATH,
    isTest,
    fpscpPath: isProduction ? FPSCP_BASE_PRO_PATH : isTest ? FPSCP_BASE_PATH : FPSCP_BASE_PATH,
    fpscpV2Path
}
