// mutations
const setUserInfo = 'set user info'
const SetUserInfoSuger = `${setUserInfo} suger`
const SetEntryCount = 'set entry count'
const setPurviews = 'set purviews'

export default {
  setUserInfo,
  setPurviews,
  SetUserInfoSuger,
  SetEntryCount
}

export enum ActionCommands {
  getPurviews = 'get user purviews'
}
