import Axios, { AxiosInstance } from 'axios'
import customConfig from '../custom-config'
import { RequestCode } from '@/utils/codes'
import { ActionCommands } from '@/store/commands'
import VueStore from '@/store/index'
import { getLoginInfo } from '@/utils/login-management'
import { SMS_LOGIN, PWD_LOGIN } from './urls'

// 家医服务器请求配置i
export const axiosHlc = decoratorAxios(Axios.create({
    baseURL: customConfig.fpscpPath + customConfig.apiPath1 + '/',
    validateStatus: (status) => status >= 200 && status < 300
}))

// 社圈服务器请求配置
export const axiosSoic = decoratorAxios(Axios.create({
    baseURL: customConfig.COMMUNITY_PATH + customConfig.apiPath + '/'
}))

// 运营服务器请求配置
export const axiosOm = decoratorAxios(Axios.create({
    baseURL: customConfig.OM_PATH + customConfig.apiPath + '/',
    validateStatus: (status) => status >= 200 && status < 300,
}))

// 心康2.0版本请求配置
// 运营服务器请求配置
export const axiosV2 = decoratorAxios(Axios.create({
  // baseURL: customConfig.FpscpV2Path + customConfig.apiPath + '/',
  baseURL: customConfig.fpscpV2Path,
  validateStatus: (status) => status >= 200 && status < 300,
}))
// 爬虫服务器请求配置
export const axioSpider = Axios.create({
    baseURL: customConfig.SPIDER_PATH
})

function getUserInfo(): Promise<boolean> {
    return new Promise((resolve) => {
        VueStore.dispatch(ActionCommands.getPurviews)
            .then(() => resolve(true))
            .catch(() => resolve(false))
    })
}

function replaceToken(args: Array<any>): Array<any> {
    const jsonArg = JSON.stringify(args)
    const newJsonArg = jsonArg.replace(/(['"]jwt_token['"]:\s*["']).*?(['"])/, `$1${getLoginInfo()}$2`)
    try {
        return JSON.parse(newJsonArg)
    } catch (_) {
        return args
    }
}

const whiteList = [SMS_LOGIN, PWD_LOGIN]

// 添加jwt过期刷新拦截处理
function decoratorAxios(instance: AxiosInstance) {
    const attrs = Object.getOwnPropertyNames(instance)
    const ins = instance as any
    attrs.forEach((attr: string) => {
        if (typeof ins[attr] === 'function') {
            const cache = ins[attr]
            ins[attr] = async function (...args: Array<any>) {
                const result = await cache(...args)
                if (whiteList.includes(result.config.url)) {
                    return result
                }
                if (result.status >= 200 && result.status < 300) {
                    const data = result.data
                    if (data.code === RequestCode.expired) {
                        // 重新请求token
                        // 重新发起请求
                        // 3次请求都没有获取到数据，就退出
                        let i = 0
                        const r = async (): Promise<boolean> => {
                            const res = await getUserInfo()
                            if (!res && i < 3) {
                                i++
                                const val = await r()
                                return val
                            } else {
                                return res
                            }
                        }
                        const res = await r()
                        if (res) {
                            // 使用新获取到的jwt去重新获取数据
                            const result = await cache(...replaceToken(args))
                            return result
                        }
                        // 否则返回原有的结果
                        return result
                    }
                }
                return result
            }
        }
    })
    return ins
}
