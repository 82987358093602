// 登录状态管理
//
// isLogin()      判断是否已登录
// getLoginInfo() 返回保存的登录信息从localStorage中
// setLoginInfo(Object) 保存登录信息到localStorage
// clearLoginInfo()   清除登录信息
import VuexStore from '@/store/index'
import commands from '@/store/commands'

// 判断是否已登录
export const isLogin = () => !!getLoginInfo()

const LOCAL_KEY = 'user-jwt'

// 返回保存的登录信息从localStorage中
export const getLoginInfo = (): string => {
  return localStorage.getItem(LOCAL_KEY) || ''
}

// 保存登录信息到localStorage
export const setLoginInfo = (loginInfo: string) => {
  localStorage.setItem(LOCAL_KEY, loginInfo)
}

// 清除登录信息
export const clearLoginInfo = () => localStorage.removeItem(LOCAL_KEY)

// 快捷退出登录方法
export const logout = (): void => {
  VuexStore.commit(commands.setUserInfo, {})
  VuexStore.commit(commands.setPurviews, [])
  VuexStore.commit(commands.SetEntryCount, 0)
  clearLoginInfo()
}
