import axios from 'axios'
import Vue, { VNode } from 'vue'
import { getLoginInfo } from './login-management'
import { Response } from '../interfaces'
import { DirectiveBinding } from 'vue/types/options'
import { RequestCode } from './codes'

type HttpMethod = 'request' | 'get' | 'delete' | 'head' | 'options' | 'post' | 'put' | 'patch'

interface ElementNode {
  content: string
}

export default function VueExtend() {
  Vue.prototype.$normalizeStringForRegexp = escapeRegExp
  Vue.prototype.$printArea = printArea
  Vue.prototype.$successCb = successCb

  /**
     * 将字符串格式化为安全的字符串用于正则
     *
     * @param {String} str
     * @returns 格式化结果
     */
  function escapeRegExp(str: string = '') {
    return str.replace(/([-.*+?^${}()|[\]/\\])/g, '\\$1')
  }

  /**
   * 局部打印
   * @param {string} doc html
   */
  function printArea(doc: string) {
    var iframe = document.createElement('iframe')
    iframe.onload = setPrint
    iframe.style.visibility = 'hidden'
    iframe.style.position = 'fixed'
    iframe.style.right = '0'
    iframe.style.bottom = '0'
    document.body.appendChild(iframe)

    function closePrint(this: any) {
      document.body.removeChild(this.__container__)
    }

    function setPrint(this: any) {
      this.contentDocument.body.innerHTML = doc
      this.contentWindow.__container__ = this
      this.contentWindow.onbeforeunload = closePrint
      this.contentWindow.onafterprint = closePrint
      this.contentWindow.focus() // Required for IE
      this.contentWindow.print()
    }
  }
}

/**
 * 进一步封装请求代码
 * 如果没有指定请求地址，抛出错误
 * 如果不指定发起何种请求，默认为get请求
 * @param {String} url 请求的地址
 * @param {String|Object|undefined} method 方法名或者是参数，默认发起get请求
 * @param {Object|undefined} params 参数
 * @returns {Promise}
 * @deprecated
 */
export function request(url: string, method: HttpMethod | object = 'get', params: any = {}) {
  var config, csrfParam, csrfToken
  if (!url) {
    throw new Error('必须指定请求路径')
  }
  if (!/^\/api\/v\d+?/.test(url)) {
    url = `/api/v1/${url}`.replace(/\/+/g, '/')
  }
  if (typeof method === 'object') {
    params = method
    method = 'get'
  }
  config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Cache-control': 'no-cache'
    },
    params: {
      jwt_token: getLoginInfo()
    }
  }
  if (~['request', 'get', 'delete', 'head', 'options'].indexOf(method)) {
    Object.assign(config.params, params)
    return (axios as any)[method](url, config)
  }
  if (~['post', 'put', 'patch'].indexOf(method)) {
    let paramNode: ElementNode = (document.querySelector('meta[name="csrf-param"]') || {}) as ElementNode
    let tokenNode: ElementNode = (document.querySelector('meta[name="csrf-token"]') || {}) as ElementNode
    csrfParam = paramNode.content
    csrfToken = tokenNode.content
    return (axios as any)[method](url, Object.assign({
      [csrfParam]: csrfToken
    }, Object.assign(config.params, params)), config)
  }
  throw new Error('不支持的方法：' + method)
}

const cache = Vue.prototype.successCb

/**
 * 请求成功后返回请求到的数据，如果为空返回null，否则不调用回调
 *
 * @param this Vue实例
 * @param result 请求的返回值
 * @param cb 请求成功的处理回调
 * @param config, .error 配置是否提示请求的状态错误, warn配置是否提示请求的编码错误
 */
function successCb(
  this: Vue,
  result: Response,
  cb: Function,
  config: { error: boolean, warn: boolean } = { error: true, warn: true },
) {
  if (cache) {
    cache()
  }
  if (result.status >= 200 && result.status < 300) {
    const data = result.data
    if (data.code === RequestCode.success) {
      cb && cb(data.data)
    } else if (data.code === RequestCode.empty) {
      cb && cb(null)
    } else {
      if (config.warn) {
        this.$message(data.message || data.err_msg || data.msg)
      }
    }
  } else {
    if (config.error) {
      this.$message(result.message || result.status.toString())
    }
  }
}

/**
 * 鉴权指令 v-permissions='[权限名1, 权限名2]'
 */
Vue.directive('permissions', {
  inserted(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const ctx: any = vnode.context || {}
    const $store = ctx.$store || {}
    const permissions = ($store.state || {}).purviews || []
    const bindValue = binding.value

    if (!bindValue || !permissions.length) return
    if (Array.isArray(bindValue)) {
      if (!bindValue.filter(f => f).length) return
    }

    if (typeof bindValue === 'string') {
      if (!permissions.includes(bindValue)) {
        if (el.parentNode) {
          el.parentNode.removeChild(el)
        }
      }
      return
    }

    if (Array.isArray(bindValue)) {
      const isExist = bindValue.some((val: any) => permissions.includes(val))
      if (!isExist) {
        if (el.parentNode) {
          el.parentNode.removeChild(el)
        }
      }
    }
  },
})
