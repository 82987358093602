import {
  // 运营管理系统
  ROLES,
  DEPARTMENTS,
  SPIDER_TASKS,
  SPIDER_TEMPLATES,
  SPIDER_TASK,
  SPIDER_DATA,
  PWD_LOGIN,
  PERMISSIONS,
  OM_EMPLOYEES,
  USERS,
  USER_BY_JWT,
  SMS_LOGIN,
  SPIDER_DATA_AUDIT,
  ORGS_LIST,
  POST_FILE,
  NOTES,
  ORG_EMPLOYEES,
  ORG_TEAMS,
  ORGS_ISADMIN_PHONE,
} from '../urls'
import { axiosOm, axioSpider } from '@/http/http'
import { getLoginInfo } from '@/utils/login-management'
import { Response } from '../../interfaces'
import { Paging } from '@/utils/codes'

/** *** 运营管理系统 *****/
// 获取角色列表
function API_GET_ROLE_LIST(attr: {
  search?: string
  status?: string
  st?: string
  et?: string
  paging?: Paging
  page?: number
  per_page?: number
} = { paging: Paging.yes, page: 1, per_page: 25 }): Promise<Response> {
  return axiosOm.get(ROLES, {
    params: {
      'jwt_token': getLoginInfo(),
      ...attr
    }
  })
}

// 增角色
function API_POST_ROLE(role: { name: string }): Promise<Response> {
  return axiosOm.post(ROLES, {
    'jwt_token': getLoginInfo(),
    role
  })
}

// 删角色
function API_DELETE_ROLE(id: number): Promise<Response> {
  return axiosOm.delete(ROLES + `/${id}`, {
    params: {
      'jwt_token': getLoginInfo(),
    }
  })
}

// 查角色
function API_GET_ROLE(id: number): Promise<Response> {
  return axiosOm.get(ROLES + `/${id}`, {
    params: {
      'jwt_token': getLoginInfo(),
    }
  })
}

// 改角色
function API_PUT_ROLE(id: number, role: { name: string }): Promise<Response> {
  return axiosOm.put(ROLES + `/${id}`, {
    'jwt_token': getLoginInfo(),
    role
  })
}

// 增角色权限
function API_POST_ROLE_PERMISSIONS(id: number, power_ids: string): Promise<Response> {
  return axiosOm.post(ROLES + `/${id}/powers`, {
    'jwt_token': getLoginInfo(),
    power_ids,
  })
}

// 改角色权限
function API_PUT_ROLE_PERMISSIONS(id: number, power_ids: string): Promise<Response> {
  return axiosOm.put(ROLES + `/${id}/powers`, {
    'jwt_token': getLoginInfo(),
    power_ids,
  })
}

// 获取员工列表
function API_GET_EMPLOYEE_LIST(attr: {
  search?: string,
  status?: string,
  st?: string,
  et?: string,
  paging?: Paging,
  page?: number,
  per_page?: number
} = {
    paging: Paging.yes,
    page: 1,
    per_page: 25
  }): Promise<Response> {
  return axiosOm.get(OM_EMPLOYEES, {
    params: {
      'jwt_token': getLoginInfo(),
      ...attr
    }
  })
}

// 获取员工信息
function API_GET_EMPLOYEE(id: number): Promise<Response> {
  return axiosOm.get(OM_EMPLOYEES + `/${id}`, {
    params: {
      'jwt_token': getLoginInfo(),
    }
  })
}

// 修改员工信息
interface EmployeeItem {
  email: string
  position: string
}
function API_PUT_EMPLOYEE(
  id: number,
  attr: {
    employe: EmployeeItem,
    user: UserItem,
    dep_ids: string
  }
): Promise<Response> {
  return axiosOm.put(OM_EMPLOYEES + `/${id}`, {
    'jwt_token': getLoginInfo(),
    ...attr,
  })
}

// 删除员工
function API_DELETE_EMPLOYEE(id: number): Promise<Response> {
  return axiosOm.delete(OM_EMPLOYEES + `/${id}`, {
    params: {
      'jwt_token': getLoginInfo(),
    }
  })
}

// 添加员工
function API_POST_EMPLOYEE(attr: {
  employe: EmployeeItem,
  dep_ids: string,
  user: UserItem
}): Promise<Response> {
  return axiosOm.post(OM_EMPLOYEES, {
    'jwt_token': getLoginInfo(),
    ...attr,
  })
}

// 员工启用
function API_PUT_EMPLOYEE_ENABLE(id: number): Promise<Response> {
  return axiosOm.put(OM_EMPLOYEES + `/${id}/enable`, {
    'jwt_token': getLoginInfo(),
  })
}

// 员工停用
function API_PUT_EMPLOYEE_DISABLE(id: number): Promise<Response> {
  return axiosOm.put(OM_EMPLOYEES + `/${id}/disable`, {
    'jwt_token': getLoginInfo(),
  })
}

// 获取机构部门列表
function API_GET_DEPARTMENT_LIST(attr: {
  search?: string
  status?: string
  st?: string
  et?: string
  paging?: Paging
  page?: number
  per_page?: number
} = { paging: Paging.yes, page: 1, per_page: 25 }): Promise<Response> {
  return axiosOm.get(DEPARTMENTS, {
    params: {
      'jwt_token': getLoginInfo(),
      ...attr,
    }
  })
}

// 获取部门信息
function API_GET_DEPARTMENT(id: number): Promise<Response> {
  return axiosOm.get(DEPARTMENTS + `/${id}`, {
    params: {
      'jwt_token': getLoginInfo(),
    }
  })
}

// 创建部门信息
function API_POST_DEPARTMENT(dep: { name: string, leader: number }): Promise<Response> {
  return axiosOm.post(DEPARTMENTS, {
    'jwt_token': getLoginInfo(),
    dep,
  })
}

// 更新部门信息
function API_PUT_DEPARTMENT(id: number, dep: { name: string, leader: number }): Promise<Response> {
  return axiosOm.put(DEPARTMENTS + `/${id}`, {
    'jwt_token': getLoginInfo(),
    dep,
  })
}

// 删除部门信息
function API_DELETE_DEPARTMENT(id: number): Promise<Response> {
  return axiosOm.delete(DEPARTMENTS + `/${id}`, {
    params: {
      'jwt_token': getLoginInfo(),
    }
  })
}

// 密码登录
function API_POST_PWD_LOGIN(phone: string, pwd: string): Promise<Response> {
  return axiosOm.post(PWD_LOGIN, {
    phone,
    password: pwd,
  })
}

// 验证码登录
function API_POST_SMS_LOGIN(phone: string, code: string): Promise<Response> {
  return axiosOm.post(SMS_LOGIN, {
    phone,
    code,
  })
}

interface PagingInterface {
  paging?: Paging
  page?: number
  per_page?: number
}
// 获取权限列表
function API_GET_PERMISSION_LIST(attr: PagingInterface = { paging: Paging.yes, page: 1, per_page: 25 }): Promise<Response> {
  return axiosOm.get(PERMISSIONS, {
    params: {
      jwt_token: getLoginInfo(),
      ...attr
    }
  })
}

// 获取权限详细
function API_GET_PERMISSION(id: number): Promise<Response> {
  return axiosOm.get(PERMISSIONS + `/${id}`, {
    params: {
      jwt_token: getLoginInfo(),
    }
  })
}

// 更新权限
interface PowerItem {
  power_id?: number
  source?: string
  code?: string
  name?: string
}
function API_PUT_PERMISSIONS(id: number, attr: { power: PowerItem, parent_id: number }): Promise<Response> {
  return axiosOm.put(PERMISSIONS + `/${id}`, {
    jwt_token: getLoginInfo(),
    ...attr
  })
}

// 新增权限
function API_POST_PERMISSIONS(attr: { power: PowerItem, parent_id: number }): Promise<Response> {
  return axiosOm.post(PERMISSIONS, {
    jwt_token: getLoginInfo(),
    ...attr
  })
}

// 删除权限
function API_DELETE_PERMISSIONS(id: number): Promise<Response> {
  return axiosOm.delete(PERMISSIONS + `/${id}`, {
    jwt_token: getLoginInfo(),
  })
}

// 获取短信验证码
function API_POST_NOTES(phone: string): Promise<Response> {
  return axiosOm.post(NOTES, { phone })
}

// 获取用户列表
function API_GET_USER_LIST(attr: {
  search?: string
  status?: string
  st?: string
  et?: string
  paging?: Paging
  page?: number
  per_page?: number
} = {
    paging: Paging.yes,
    page: 1,
    per_page: 25
  }): Promise<Response> {
  return axiosOm.get(USERS, {
    params: {
      jwt_token: getLoginInfo(),
      ...attr
    }
  })
}

// 获取用户信息
function API_GET_USER(id: number): Promise<Response> {
  return axiosOm.get(USERS + `/${id}`, {
    params: {
      jwt_token: getLoginInfo(),
    }
  })
}

// 删除用户
function API_DELETE_USER(id: number): Promise<Response> {
  return axiosOm.delete(USERS + `/${id}`, {
    jwt_token: getLoginInfo(),
  })
}

// 修改用户
interface UserItem {
  phone: string
  nickname?: string
  name?: string
  sex?: string // 0 1-男 2-女 9
  birthday?: string
  idno?: string
  head_url?: string
  password?: string
  password_confirmation?: string
}
function API_PUT_USER(id: number, user: UserItem): Promise<Response> {
  return axiosOm.put(USERS + `/${id}`, {
    jwt_token: getLoginInfo(),
    user
  })
}

// 创建用户
function API_POST_USER(user: UserItem): Promise<Response> {
  return axiosOm.delete(USERS, {
    jwt_token: getLoginInfo(),
    user,
  })
}

// 获取用户信息
function API_GET_USER_BY_JWT(): Promise<Response> {
  return axiosOm.get(USER_BY_JWT, {
    params: {
      jwt_token: getLoginInfo(),
    }
  })
}

// 启用用户
function API_PUT_USER_ENABLE(id: number): Promise<Response> {
  return axiosOm.put(USERS + `/${id}/enable`, {
    jwt_token: getLoginInfo(),
  })
}

// 停用用户
function API_PUT_USER_DISABLE(id: number): Promise<Response> {
  return axiosOm.put(USERS + `/${id}/disable`, {
    jwt_token: getLoginInfo(),
  })
}

// 用户角色添加
function API_POST_USER_ADD_ROLE(id: number, role_ids: string): Promise<Response> {
  return axiosOm.post(USERS + `/${id}/roles`, {
    jwt_token: getLoginInfo(),
    role_ids
  })
}

// 用户角色修改
function API_PUT_USER_EDIT_ROLE(id: number, role_ids: string): Promise<Response> {
  return axiosOm.put(USERS + `/${id}/roles`, {
    jwt_token: getLoginInfo(),
    role_ids
  })
}

// 重置密码
function API_PUT_PASSWORD_RESET(id: number): Promise<Response> {
  return axiosOm.put(USERS + `/${id}/password/reset`, {
    jwt_token: getLoginInfo(),
  })
}
// 获取机构列表
function API_GET_ORGS_LIST(attr: {
  search?: string
  status?: string
  st?: string
  et?: string
  province?: string
  city?: string
  county?: string
  paging?: Paging
  page?: number
  per_page?: number
} = {
    paging: Paging.yes,
    page: 1,
    per_page: 25
  }): Promise<Response> {
  return axiosOm.get(ORGS_LIST, {
    params: {
      jwt_token: getLoginInfo(),
      ...attr
    }
  })
}
// 获取单个机构
function API_GET_ORG(id: string,): Promise<Response> {
  return axiosOm.get(ORGS_LIST + `/${id}`, {
    params: {
      jwt_token: getLoginInfo(),
    }
  })
}

// 创建机构
function API_POST_ORG(
  org: {
    api_account: string
    api_passwd: string
    name: string // 名称
    area: string // 机构唯一编码
    province: string // 省
    city: string // 市
    county: string // 区县
    location: string // 详细地址
    phone_number: string // 机构电话
    intro: string // 简介
    electronic_seal: string // 机构印章图
    email: string // 邮箱
    telephone: string // 机构固定电话
    population?: string // 常住人口数
    theaged?: string // 老年人总数
    hypertension?: string // 高血压总数
    glycuresis?: string // 糖尿病总数
    maternal?: string // 孕产妇总数
    child?: string // 0-6岁儿童
    psychotic?: string // 重性精神病
    tuberculosis?: string // 肺结核
  }
): Promise<Response> {
  // if (isOmTest()) return mockOrgList()
  return axiosOm.post(ORGS_LIST, {
    jwt_token: getLoginInfo(),
    org
  })
}

// 创建机构管理员
function API_POST_ADMINISTERS(id: string,
  user: {
    password?: string
    password_confirmation?: string
    phone: string
    nickname?: string
    name?: string
    sex?: string
    birthday?: string
    idno: string
    head_url: string
  },
  employe: {
    position: string
    intro: string
  }
): Promise<Response> {
  return axiosOm.post(ORGS_LIST + `/${id}/administers`, {
    jwt_token: getLoginInfo(),
    user,
    employe
  })
}

// 删除机构管理员
function API_DEL_ADMINISTERS(id: string, admin_id: string): Promise<Response> {
  return axiosOm.delete(ORGS_LIST + `/${id}/administers/${admin_id}`, {
    params: {
      'jwt_token': getLoginInfo(),
    }
  })
}

// 通过手机号获取用户信息
function API_GET_EMPLOYES_INFOS(phone: string): Promise<Response> {
  return axiosOm.get(ORGS_LIST + `/employes/${phone}/infos`, {
    params: {
      jwt_token: getLoginInfo()
    }
  })
}

// 上传文件
function API_POST_UPLOAD_SEAL_PIC(params: FormData): Promise<Response> {
  return axiosOm.post(POST_FILE, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 默认分页数据
const DEFAULT_PAGINATION: Pagination = { paging: Paging.yes, page: 1, per_page: 25 }

interface Pagination {
  paging?: Paging
  page?: number
  per_page?: number
}

// 获取机构成员
interface Org_Employees_Params extends Pagination {
  search?: string
  team_id?: number | string
}
function API_GET_ORG_EMPLOYEES(id: number, params: Org_Employees_Params = DEFAULT_PAGINATION): Promise<Response> {
  return axiosOm.get(ORG_EMPLOYEES.replace(':org_id', `${id}`), {
    params: {
      jwt_token: getLoginInfo(),
      ...params,
    }
  })
}

// 获取机构团队
interface Org_Teams_Params extends Pagination {
  search?: string
  paging?: Paging
  page?: number
  per_page?: number
}
function API_GET_ORG_TEAMS(id: number, params: Org_Teams_Params = DEFAULT_PAGINATION): Promise<Response> {
  return axiosOm.get(ORG_TEAMS.replace(':org_id', `${id}`), {
    params: {
      jwt_token: getLoginInfo(),
      ...params,
    }
  })
}

// 获取机构团队信息
function API_GET_ORG_TEAM(orgId: number, teamId: number): Promise<Response> {
  return axiosOm.get(ORG_TEAMS.replace(':org_id', `${orgId}`) + `/${teamId}`, {
    params: {
      jwt_token: getLoginInfo(),
    }
  })
}
// 查询手机号是否已是机构管理员
function API_GEI_ORG_ISADMIN_PHONE(phone: string): Promise<Response> {
  return axiosOm.get(ORGS_ISADMIN_PHONE, {
    params: {
      jwt_token: getLoginInfo(),
      phone,
    }
  })
}

/** 爬虫管理 */
// 查询任务列表
function API_GET_SPIDER_TASKS(params: any): Promise<Response> {
  return axioSpider.get(SPIDER_TASKS, {
    params
  })
}
// 创建任务
function API_POST_SPIDER_TASKS(base_config: any, custom_config: any
): Promise<Response> {
  return axioSpider.post(SPIDER_TASK, {
    base_config,
    custom_config
  })
}
// 修改任务
function API_PUT_SPIDER_TASK(task_id: string, base_config: any, custom_config: any
): Promise<Response> {
  return axioSpider.put(SPIDER_TASK, {
    task_id,
    base_config,
    custom_config
  })
}
// 删除任务
function API_DEL_SPIDER_TASKS(task_id: number): Promise<Response> {
  return axioSpider.delete(SPIDER_TASK + `/${task_id}`)
}
// 切换任务状态
function API_PUT_SPIDER_TASKS_STATUS(task_id: number, params: any): Promise<Response> {
  return axioSpider.put(SPIDER_TASK + `/${task_id}` + `/state?state=${params}`, {
  })
}
// 查询模版
function API_GET_SPIDER_TEMPLATES(): Promise<Response> {
  return axioSpider.get(SPIDER_TEMPLATES)
}
// 根据任务id获取爬虫采集的数据
function API_GET_SPIDER_DATA(params: any): Promise<Response> {
  return axioSpider.get(SPIDER_DATA, {
    params
  })
}
// 删除文章
function API_DEL_SPIDER_ARTICLE(data_id: string): Promise<Response> {
  return axioSpider.delete(SPIDER_DATA + `/${data_id}`)
}
// 审核文章
function API_PUT_SPIDER_DATA_AUDIT(data_id: string, audit_state: number): Promise<Response> {
  return axioSpider.put(SPIDER_DATA_AUDIT, {
    data_id,
    audit_state
  })
}

export {
  /* 运营管理系统 */
  API_GET_ROLE_LIST,
  API_GET_EMPLOYEE_LIST,
  API_GET_DEPARTMENT_LIST,
  API_POST_PWD_LOGIN,
  API_GET_PERMISSION_LIST,
  API_GET_PERMISSION,
  API_PUT_PERMISSIONS,
  API_POST_PERMISSIONS,
  API_DELETE_PERMISSIONS,
  API_GET_DEPARTMENT,
  API_POST_DEPARTMENT,
  API_PUT_DEPARTMENT,
  API_DELETE_DEPARTMENT,
  API_GET_EMPLOYEE,
  API_PUT_EMPLOYEE,
  API_DELETE_EMPLOYEE,
  API_PUT_EMPLOYEE_ENABLE,
  API_PUT_EMPLOYEE_DISABLE,
  API_POST_EMPLOYEE,
  API_POST_ROLE,
  API_DELETE_ROLE,
  API_GET_ROLE,
  API_PUT_ROLE,
  API_POST_ROLE_PERMISSIONS,
  API_PUT_ROLE_PERMISSIONS,
  API_GET_USER_BY_JWT,
  API_GET_USER_LIST,
  API_GET_USER,
  API_DELETE_USER,
  API_PUT_USER,
  API_POST_USER,
  API_PUT_USER_ENABLE,
  API_PUT_USER_DISABLE,
  API_POST_SMS_LOGIN,
  API_POST_USER_ADD_ROLE,
  API_PUT_USER_EDIT_ROLE,
  API_PUT_PASSWORD_RESET,
  API_GET_ORGS_LIST,
  API_POST_ORG,
  API_POST_UPLOAD_SEAL_PIC,
  API_POST_NOTES,
  API_POST_ADMINISTERS,
  API_GET_EMPLOYES_INFOS,
  API_DEL_ADMINISTERS,
  API_GET_ORG_EMPLOYEES,
  API_GET_ORG_TEAMS,
  API_GET_ORG_TEAM,
  API_GET_ORG,
  API_GEI_ORG_ISADMIN_PHONE,
  // 爬虫
  API_GET_SPIDER_TASKS,
  API_GET_SPIDER_TEMPLATES,
  API_POST_SPIDER_TASKS,
  API_DEL_SPIDER_TASKS,
  API_PUT_SPIDER_TASKS_STATUS,
  API_PUT_SPIDER_TASK,
  API_GET_SPIDER_DATA,
  API_PUT_SPIDER_DATA_AUDIT,
  API_DEL_SPIDER_ARTICLE
}
