interface ValueTable {
    label: string
    code: string
}

// 学历
export const educations: Array<ValueTable> = [
    {
        label: '研究生',
        code: '1'
    }, {
        label: '大学本科',
        code: '2'
    }, {
        label: '大学专科和专科学校',
        code: '3'
    }, {
        label: '中等专业学校',
        code: '4'
    }, {
        label: '技工学校',
        code: '5'
    }, {
        label: '高中',
        code: '6'
    }, {
        label: '初中',
        code: '7'
    }, {
        label: '小学',
        code: '8'
    }, {
        label: '文盲或半文盲',
        code: '9'
    }, {
        label: '不详',
        code: '10'
    }]

// 职业
export const professions: Array<ValueTable> = [
    { label: '国家机关、党群组织、企业、事业单位负责人', code: '1' },
    { label: '专业技术人员', code: '2' },
    { label: '办事人员和有关人员', code: '3' },
    { label: '商业、服务业人员', code: '4' },
    { label: '农、林、牧、渔、水利业生产人员', code: '5' },
    { label: '生产、运输设备操作人员及有关人员', code: '6' },
    { label: '军人', code: '7' },
    { label: '不便分类的其他从业人员', code: '8' },
    { label: '无职业', code: '9' }
]

// 医疗费用支付方式
export const payTypes: Array<ValueTable> = [
    { label: '城镇职工基本医疗保险', code: '1' },
    { label: '城镇居民基本医疗保险', code: '2' },
    { label: '新型农村合作医疗', code: '3' },
    { label: '贫困救助', code: '4' },
    { label: '商业医疗保险', code: '5' },
    { label: '全公费', code: '6' },
    { label: '全自费', code: '7' },
    { label: '其他', code: '8' }
]

// 过敏史
export const allergies: Array<ValueTable> = [
    { label: '无', code: '1' },
    { label: '青霉素', code: '2' },
    { label: '磺胺', code: '3' },
    { label: '链霉素', code: '4' },
    { label: '其他', code: '5' }
]
// 慢性病
export const chronic: Array<ValueTable> = [
    { label: '无', code: '1' },
    { label: '高血压', code: '2' },
    { label: '糖尿病', code: '3' },
    { label: '脑卒中', code: '4' },
    { label: '冠心病', code: '5' },
    { label: '哮喘', code: '6' },
    { label: '职业病', code: '7' },
    { label: '其他疾病', code: '8' }
]
// 暴露史
export const exposures: Array<ValueTable> = [
    { label: '无', code: '1' },
    { label: '化学品', code: '2' },
    { label: '毒物', code: '3' },
    { label: '射线', code: '4' }
]

export const diseases: Array<ValueTable> = [
    { label: '无', code: '1' },
    { label: '高血压', code: '2' },
    { label: '糖尿病', code: '3' },
    { label: '冠心病', code: '4' },
    { label: '慢性阻塞性肺疾病', code: '5' },
    { label: '恶性肿瘤', code: '6' },
    { label: '脑卒中', code: '7' },
    { label: '严重精神疾病', code: '8' },
    { label: '结核病', code: '9' },
    { label: '肝炎', code: '10' },
    { label: '其他法定传染病', code: '11' },
    { label: '职业病', code: '12' },
    { label: '其他', code: '13' }
]

// 残疾情况
export const disabilities: Array<ValueTable> = [
    { label: '无残疾', code: '1' },
    { label: '视力残疾', code: '2' },
    { label: '听力残疾', code: '3' },
    { label: '言语残疾', code: '4' },
    { label: '肢体残疾', code: '5' },
    { label: '智力残疾', code: '6' },
    { label: '精神残疾', code: '7' },
    { label: '其他残疾', code: '8' }
]

// 家族史
export const familyDiseases: Array<ValueTable> = [
    { label: '无', code: '1' },
    { label: '高血压', code: '2' },
    { label: '糖尿病', code: '3' },
    { label: '冠心病', code: '4' },
    { label: '慢性阻塞性肺疾病', code: '5' },
    { label: '恶性肿瘤', code: '6' },
    { label: '脑卒中', code: '7' },
    { label: '重性精神疾病', code: '8' },
    { label: '结核病', code: '9' },
    { label: '肝炎', code: '10' },
    { label: '先天畸形', code: '11' },
    { label: '其他', code: '12' }
]

// 特殊人群
export const specialPerson: Array<string> = [
    '患风湿关节炎', '关节病', '重性精神障碍', '急性脑血管疾病后遗症',
    '慢性阻塞性肺疾病', '肺源性心脏病', '糖尿病', '结核病',
    '风湿性心脏病', '老年性痴呆', '心脏病并发心功能不全',
    '冠状动脉粥样硬化性心脏病', '心肌病', '血友病', '慢性支气管炎',
    '支气管哮喘', '肾病综合征', '慢性肾功能不全', '慢性肾炎',
    '强制性脊柱炎', '癫痫', '肝硬化', '慢性活动性肝炎', '丙型病毒性肝炎',
    '高血压', '系统性红斑狼疮', '白血病', '再生障碍性贫血',
    '甲状腺功能减退', '甲亢', '帕金森', '重症肌无力',
    '器官移植术后的抗排斥药物治疗', '地中海贫血',
    '骨髓增生异常综合征及骨髓增生性疾病', '肺间质纤维化', '矽肺',
    '心脏瓣膜置换术后', '心脏搭桥术后', '血管支架植入后', '各类严重肿瘤'
]

// '合约状态'
export const contractStatus = [
    {
        value: '1', label: '已申请签约状态'
    },
    {
        value: '2', label: '已申请签约取消状态'
    },
    {
        value: '3', label: '已申请签约同意状态'
    },
    {
        value: '4', label: '已申请签约作废状态'
    },
    {
        value: '5', label: '同意作废申请状态'
    },
    {
        value: '6', label: '拒绝作废申请状态'
    },
    {
        value: '7', label: '协议过期状态'
    }
]

// 员工状态
export const employeeStatus = [{
    value: '0', label: '未入职'
}, {
    value: '1', label: '已入职'
}, {
    value: '2', label: '已废弃'
}]

export const maritals = [
    { code: '1', label: '未婚' },
    { code: '2', label: '已婚' },
    { code: '3', label: '丧偶' },
    { code: '4', label: '离婚' },
    { code: '5', label: '未说明的婚姻状况' },
]
export const airTypes = [
    { code: '1', label: '无' },
    { code: '2', label: '抽油烟机' },
    { code: '3', label: '换气扇' },
    { code: '4', label: '烟囱' },
]
export const fuelTypes = [
    { code: '1', label: '液化气' },
    { code: '2', label: '煤' },
    { code: '3', label: '天然气' },
    { code: '4', label: '沼气' },
    { code: '5', label: '柴火' },
    { code: '6', label: '其他' },
]
export const drinkTypes = [
    { code: '1', label: '自来水' },
    { code: '2', label: '经净化过滤的水' },
    { code: '3', label: '井水' },
    { code: '4', label: '河湖水' },
    { code: '5', label: '塘水' },
    { code: '6', label: '其他' },
]
export const toiletTypes = [
    { code: '1', label: '卫生厕所' },
    { code: '2', label: '一格或二格粪池式' },
    { code: '3', label: '马桶' },
    { code: '4', label: '露天粪坑' },
    { code: '5', label: '简易棚厕' },
]
export const stockTypes = [
    { code: '1', label: '无' },
    { code: '2', label: '单设' },
    { code: '3', label: '室内' },
    { code: '4', label: '室外' },
]
export const bloodTypes = [
    { code: '1', label: 'A型' },
    { code: '2', label: 'B型' },
    { code: '3', label: 'O型' },
    { code: '4', label: 'AB型' },
    { code: '5', label: '不详' },
]
export const RhTypes = [
    { code: '1', label: '阴性' },
    { code: '2', label: '阳型' },
    { code: '3', label: '不详' },
]
export const Person_crows = [
    { code: '1', label: '65岁以上' },
    { code: '2', label: '0-6岁儿童' },
    { code: '3', label: '孕产妇' },
    { code: '4', label: '低收入人群' },
    { code: '5', label: '贫困人群' },
    { code: '6', label: '高血压' },
    { code: '7', label: '糖尿病' },
    { code: '8', label: '重症精神病' },
    { code: '9', label: '肺结核' },
    { code: '10', label: '残疾人群' },
    { code: '11', label: '计划生育' },
    { code: '12', label: '失/伤独' },
]

export enum RequestCode {
    expired = 4003,
    success = 1001,
    empty = 1003
}

export enum Paging {
    yes = 'yes',
    no = 'no'
}
