import { VueConstructor } from 'vue'
import {
  Pagination,
  Button,
  Form,
  FormItem,
  Input,
  Menu,
  MenuItem,
  Container,
  Aside,
  Main,
  Table,
  TableColumn,
  Dialog,
  Popover,
  Select,
  Option,
  DatePicker,
  Upload,
  RadioGroup,
  Radio,
  Col,
  CheckboxGroup,
  Checkbox,
  Tabs,
  TabPane,
  Breadcrumb,
  BreadcrumbItem,
  Cascader,
  Tree,
  Message,
  PageHeader,
  MessageBox,
  Progress,
  Row
} from 'element-ui'

export default function install(V: VueConstructor) {
  V.use(Pagination)
  V.use(Button)
  V.use(Form)
  V.use(FormItem)
  V.use(Input)
  V.use(Menu)
  V.use(MenuItem)
  V.use(Container)
  V.use(Aside)
  V.use(Main)
  V.use(Table)
  V.use(TableColumn)
  V.use(Dialog)
  V.use(Popover)
  V.use(Select)
  V.use(Option)
  V.use(DatePicker)
  V.use(Upload)
  V.use(RadioGroup)
  V.use(Radio)
  V.use(Col)
  V.use(Row)
  V.use(CheckboxGroup)
  V.use(Checkbox)
  V.use(Tabs)
  V.use(TabPane)
  V.use(Breadcrumb)
  V.use(BreadcrumbItem)
  V.use(Cascader)
  V.use(Tree)
  V.use(PageHeader)
  V.use(Progress)

  V.prototype.$message = Message
  V.prototype.$ELEMENT = { size: 'small', zIndex: 2010 }
  V.prototype.$confirm = MessageBox.confirm
}
