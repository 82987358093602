import { axiosV2 } from '@/http/http'
import { BIND_PROJECT, SPROJECT_LIST, ORG_PROJECT } from '../urls'
import { Response } from '../../interfaces'

function API_GET_SPROJECT_LIST (): Promise<Response> {
  return axiosV2.get(SPROJECT_LIST, { })
}

function API_POST_BIND_PROJECT(org_id:any, project_data:any): Promise<Response> {
  return axiosV2.post(BIND_PROJECT, {
    org_id: org_id,
    project_data: project_data
  })
}

function API_GET_ORG_PROJECT (org_id:any): Promise<Response> {
  return axiosV2.get(ORG_PROJECT.replace(':org_id', `${org_id}`), {})
}
export {
  API_GET_SPROJECT_LIST,
  API_POST_BIND_PROJECT,
  API_GET_ORG_PROJECT
}
