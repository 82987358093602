enum PurviewCodes {
  MODULE_OPERATION = 'module_operation', // 运营管理'
  MODULE_HOME_DOCTOR = 'module_home_doctor', // 机构管理(家医业务)'
  MODULE_HLEATH_HOUSE = 'module_hleath_house', // 健康小屋'
  MODULE_NSB = 'module_nsb', // 农商行'
  MODULE_SUPPLIER = 'module_supplier', // 商家管理'
  MENU_HOME = 'menu_home', // 首页'
  MENU_HOME_HOSPITAL = 'menu_home_hospital', // 社区医院/中医联盟/中医联盟
  AUTH_HOME_HOSPITAL_ADD = 'auth_home_hospital_add', // 社区医院/中医联盟/康养家园-添加
  AUTH_HOME_HOSPITAL_EDIT = 'auth_home_hospital_edit', // 社区医院/中医联盟/康养家园-修改
  AUTH_HOME_HOSPITAL_DEL = 'auth_home_hospital_del', // 社区医院/中医联盟/康养家园-删除
  // MENU_HOME_HOSPITAL = 'menu_home_hospital', // 社区医院'
  // AUTH_HOME_HOSPITAL_QUERY = 'auth_home_hospital_query', // 社区医院-查询'
  // AUTH_HOME_HOSPITAL_ADD = 'auth_home_hospital_add', // 社区医院-添加'
  // AUTH_HOME_HOSPITAL_EDIT = 'auth_home_hospital_edit', // 社区医院-修改'
  // AUTH_HOME_HOSPITAL_DEL = 'auth_home_hospital_del', // 社区医院-删除'
  // MENU_HOME_CHMEDICINE = 'menu_home_chmedicine', // 中医联盟'
  // AUTH_HOME_CHMEDICINE_QUERY = 'auth_home_chmedicine_query', // 中医联盟-查询'
  // AUTH_HOME_CHMEDICINE_ADD = 'auth_home_chmedicine_add', // 中医联盟-添加'
  // AUTH_HOME_CHMEDICINE_EDIT = 'auth_home_chmedicine_edit', // 中医联盟-修改'
  // AUTH_HOME_CHMEDICINE_DEL = 'auth_home_chmedicine_del', // 中医联盟-删除'
  // MENU_HOME_HEALTHHOME = 'menu_home_healthhome', // 康养家园'
  // AUTH_HOME_HEALTHHOME_QUERY = 'auth_home_healthhome_query', // 康养家园-查询'
  // AUTH_HOME_HEALTHHOME_ADD = 'auth_home_healthhome_add', // 康养家园-添加'
  // AUTH_HOME_HEALTHHOME_EDIT = 'auth_home_healthhome_edit', // 康养家园-修改'
  // AUTH_HOME_HEALTHHOME_DEL = 'auth_home_healthhome_del', // 康养家园-删除'
  MENU_HOME_INDEPENDENTSIGNING = 'menu_home_independentsigning', // 自主预约签约'
  AUTH_HOME_INDEPENDENTSIGNING_QUERY = 'auth_home_independentsigning_query', // 自主预约签约'
  MENU_SOCIAL = 'menu_social', // 社圈管理'
  MENU_SOCIAL_AD = 'menu_social_ad', // 广告管理'
  AUTH_SOCIAL_AD_QUERY = 'auth_social_ad_query', // 广告管理-查询'
  AUTH_SOCIAL_AD_DETAIL = 'auth_social_ad_detail', // 广告管理-详情'
  AUTH_SOCIAL_AD_ADD = 'auth_social_ad_add', // 广告管理-添加'
  AUTH_SOCIAL_AD_EDIT = 'auth_social_ad_edit', // 广告管理-修改'
  AUTH_SOCIAL_AD_DEL = 'auth_social_ad_del', // 广告管理-删除'
  AUTH_SOCIAL_AD_ONSHELF = 'auth_social_ad_onshelf', // 广告管理-上架'
  AUTH_SOCIAL_AD_OFFSHELF = 'auth_social_ad_offshelf', // 广告管理-下架'
  MENU_SOCIAL_TREND = 'menu_social_trend', // 动态管理'
  AUTH_SOCIAL_TREND_QUERY = 'auth_social_trend_query', // 动态管理-查询'
  AUTH_SOCIAL_TREND_ADD = 'auth_social_trend_add', // 动态管理-发布'
  AUTH_SOCIAL_TREND_EXAMINE = 'auth_social_trend_examine', // 动态管理-审核'
  AUTH_SOCIAL_TREND_DETAIL = 'auth_social_trend_detail', // 动态管理-查看动态详情'
  AUTH_SOCIAL_TREND_RECOMMEND = 'auth_social_trend_recommend', // 动态管理-推荐'
  AUTH_SOCIAL_TREND_DEL = 'auth_social_trend_del', // 动态管理--删除'
  MENU_SOCIAL_REPORT = 'menu_social_report', // 举报管理'
  AUTH_SOCIAL_REPORT_QUERY = 'auth_social_report_query', // 举报管理-查询'
  AUTH_SOCIAL_REPORT_BAN = 'auth_social_report_ban', // 举报管理-封号'
  AUTH_SOCIAL_REPORT_DEBAN = 'auth_social_report_deban', // 举报管理-解封'
  MENU_SOCIAL_ARTICLE = 'menu_social_article', // 文章管理'
  AUTH_SOCIAL_ARTICLE_QUERY = 'auth_social_article_query', // 文章管理-查询'
  AUTH_SOCIAL_ARTICLE_ADD = 'auth_social_article_add', // 文章管理-添加'
  AUTH_SOCIAL_ARTICLE_ADD_POSITION = 'auth_social_article_add_position', // 文章管理-添加位置'
  AUTH_SOCIAL_ARTICLE_DETAIL = 'auth_social_article_detail', // 文章管理-查看文章详情'
  AUTH_SOCIAL_ARTICLE_RECOMMEND = 'auth_social_article_recommend', // 文章管理-推荐'
  AUTH_SOCIAL_ARTICLE_EDIT = 'auth_social_article_edit', // 文章管理-编辑'
  AUTH_SOCIAL_ARTICLE_DEL = 'auth_social_article_del', // 文章管理-删除'
  AUTH_SOCIAL_ARTICLE_OFF = 'auth_social_article_off', // 文章管理-停用'
  AUTH_SOCIAL_ARTICLE_ON = 'auth_social_article_on', // 文章管理-启用'
  MENU_SOCIAL_TOPIC = 'menu_social_topic', // 话题管理'
  AUTH_SOCIAL_TOPIC_QUERY = 'auth_social_topic_query', // 话题管理-查询'
  AUTH_SOCIAL_TOPIC_ADD = 'auth_social_topic_add', // 话题管理-添加'
  AUTH_SOCIAL_TOPIC_ADD_POSTION = 'auth_social_topic_add_postion', // 话题管理-添加位置'
  AUTH_SOCIAL_TOPIC_DETAIL = 'auth_social_topic_detail', // 话题管理-查看详情'
  AUTH_SOCIAL_TOPIC_EDIT = 'auth_social_topic_edit', // 话题管理-编辑'
  AUTH_SOCIAL_TOPIC_OFF = 'auth_social_topic_off', // 话题管理-停用'
  AUTH_SOCIAL_TOPIC_ON = 'auth_social_topic_on', // 话题管理-启用'
  AUTH_SOCIAL_TOPIC_DEL = 'auth_social_topic_del', // 话题管理-删除'
  MENU_SOCIAL_WORDS = 'menu_social_words', // 敏感词'
  AUTH_SOCIAL_WORDS_QUERY = 'auth_social_words_query', // 敏感词-查询'
  AUTH_SOCIAL_WORDS_ADD = 'auth_social_words_add', // 敏感词-添加'
  AUTH_SOCIAL_WORDS_EDIT = 'auth_social_words_edit', // 敏感词-修改'
  AUTH_SOCIAL_WORDS_ON = 'auth_social_words_on', // 敏感词-启用'
  AUTH_SOCIAL_WORDS_OFF = 'auth_social_words_off', // 敏感词-停用'
  AUTH_SOCIAL_WORDS_DEL = 'auth_social_words_del', // 敏感词-删除'
  MENU_BOSS = 'menu_boss', // 商城管理'
  MENU_CUSTOMERSERVICE = 'menu_customerservice', // 客服中心'
  MENU_CUSTOMERSERVICE_CHAT = 'menu_customerservice_chat', // 会话'
  AUTH_CUSTOMERSERVICE_CHAT_QUERY = 'auth_customerservice_chat_query', // 会话-搜索'
  AUTH_CUSTOMERSERVICE_CHAT_CHAT = 'auth_customerservice_chat_chat', // 会话-即时聊天'
  AUTH_CUSTOMERSERVICE_CHAT_ADDLABEL = 'auth_customerservice_chat_addlabel', // 会话-添加标签'
  AUTH_CUSTOMERSERVICE_CHAT_REPLYTEMPLET = 'auth_customerservice_chat_replytemplet', // 会话-快速回复'
  MENU_CUSTOMERSERVICE_LABEL = 'menu_customerservice_label', // 客户标签'
  AUTH_CUSTOMERSERVICE_LABEL_QUERY = 'auth_customerservice_label_query', // 客户标签-查询'
  AUTH_CUSTOMERSERVICE_LABEL_ADD = 'auth_customerservice_label_add', // 客户标签-新建'
  AUTH_CUSTOMERSERVICE_LABEL_EDIT = 'auth_customerservice_label_edit', // 客户标签-修改'
  AUTH_CUSTOMERSERVICE_LABEL_DEL = 'auth_customerservice_label_del', // 客户标签-删除'
  AUTH_CUSTOMERSERVICE_LABEL_CONFIG = 'auth_customerservice_label_config', // 客户标签-配置'
  MENU_CUSTOMERSERVICE_GROUPMESSAGE = 'menu_customerservice_groupmessage', // 群发消息'
  AUTH_CUSTOMERSERVICE_GROUPMESSAGE_QUERY = 'auth_customerservice_groupmessage_query', // 群发消息-查询'
  AUTH_CUSTOMERSERVICE_GROUPMESSAGE_ADD = 'auth_customerservice_groupmessage_add', // 群发消息-新建'
  AUTH_CUSTOMERSERVICE_GROUPMESSAGE_EDIT = 'auth_customerservice_groupmessage_edit', // 群发消息-修改'
  AUTH_CUSTOMERSERVICE_GROUPMESSAGE_DEL = 'auth_customerservice_groupmessage_del', // 群发消息-删除'
  MENU_CUSTOMERSERVICE_REPYTEMPLET = 'menu_customerservice_repytemplet', // 快捷消息'
  AUTH_CUSTOMERSERVICE_REPYTEMPLET_QUERY = 'auth_customerservice_repytemplet_query', // 快捷消息-查询'
  AUTH_CUSTOMERSERVICE_REPYTEMPLET_ADD = 'auth_customerservice_repytemplet_add', // 快捷消息-添加'
  AUTH_CUSTOMERSERVICE_REPYTEMPLET_EDIT = 'auth_customerservice_repytemplet_edit', // 快捷消息-修改'
  AUTH_CUSTOMERSERVICE_REPYTEMPLET_DEL = 'auth_customerservice_repytemplet_del', // 快捷消息-删除'
  MENU_SYSTEM = 'menu_system', // 系统管理'
  MENU_SYSTEM_CUSTOMER = 'menu_system_customer', // 用户管理'
  AUTH_SYSTEM_CUSTOMER_QUERY = 'auth_system_customer_query', // 用户管理-查询'
  AUTH_SYSTEM_CUSTOMER_DETAIL = 'auth_system_customer_detail', // 用户管理-查看详情'
  AUTH_SYSTEM_CUSTOMER_OFF = 'auth_system_customer_off', // 用户管理-停用'
  AUTH_SYSTEM_CUSTOMER_ON = 'auth_system_customer_on', // 用户管理-启用'
  AUTH_SYSTEM_CUSTOMER_EDIT = 'auth_system_customer_edit', // 用户管理-编辑资料'
  MENU_SYSTEM_ORGANIZATION = 'menu_system_organization', // 机构管理'
  AUTH_SYSTEM_ORGANIZATION_QUERY = 'auth_system_organization_query', // 机构管理-查询'
  AUTH_SYSTEM_ORGANIZATION_ADD = 'auth_system_organization_add', // 机构管理-新增机构'
  AUTH_SYSTEM_ORGANIZATION_DETAIL = 'auth_system_organization_detail', // 机构管理-查看机构详情'
  AUTH_SYSTEM_ORGANIZATION_MEMBER = 'auth_system_organization_member', // 机构管理-查看机构成员'
  AUTH_SYSTEM_ORGANIZATION_TEAM_MEMBER = 'auth_system_organization_team_member', // 机构管理-查看团队成员'
  MENU_SYSTEM_EMPLOYEE = 'menu_system_employee', // 员工管理'
  AUTH_SYSTEM_EMPLOYEE_QUERY = 'auth_system_employee_query', // 员工管理-查询'
  AUTH_SYSTEM_EMPLOYEE_ADD = 'auth_system_employee_add', // 员工管理-新增'
  AUTH_SYSTEM_EMPLOYEE_EDIT = 'auth_system_employee_edit', // 员工管理-编辑'
  AUTH_SYSTEM_EMPLOYEE_ON = 'auth_system_employee_on', // 员工管理-启用'
  AUTH_SYSTEM_EMPLOYEE_OFF = 'auth_system_employee_off', // 员工管理-停用'
  MENU_SYSTEM_DEPARTMENT = 'menu_system_department', // 部门管理'
  AUTH_SYSTEM_DEPARTMENT_QUERY = 'auth_system_department_query', // 部门管理-查询'
  AUTH_SYSTEM_DEPARTMENT_ADD = 'auth_system_department_add', // 部门管理-新增'
  AUTH_SYSTEM_DEPARTMENT_SETMANAGER = 'auth_system_department_setmanager', // 部门管理-设置主管'
  AUTH_SYSTEM_DEPARTMENT_EDIT = 'auth_system_department_edit', // 部门管理-编辑'
  AUTH_SYSTEM_DEPARTMENT_DEL = 'auth_system_department_del', // 部门管理-删除'
  MENU_SYSTEM_ROLE = 'menu_system_role', // 角色管理'
  AUTH_SYSTEM_ROLE_ADD = 'auth_system_role_add', // 角色管理-新增'
  AUTH_SYSTEM_ROLE_CONFIG = 'auth_system_role_config', // 角色管理-设置权限'
  AUTH_SYSTEM_ROLE_DEL = 'auth_system_role_del', // 角色管理-删除'
  AUTH_SYSTEM_ROLE_EDIT = 'auth_system_role_edit', // 角色管理-修改'
  AUTH_SYSTEM_ROLE_QUERY = 'auth_system_role_query', // 角色管理-查询'
  MENU_SYSTEM_VERSION = 'menu_version', // 版本管理'
  AUTH_SYSTEM_VERSION_ADD = 'auth_version_add', // 版本管理-新增'
  AUTH_SYSTEM_VERSION_EDIT = 'auth_version_edit', // 版本管理-修改'
  AUTH_SYSTEM_VERSION_AUTH = 'auth_version_auth', // 版本管理-上架'
  AUTH_SYSTEM_VERSION_PULL = 'auth_version_pull', // 版本管理-下架'
  AUTH_SYSTEM_VERSION_VERIFY = 'auth_version_verify', // 版本管理-验证'
  ADVERTISING = 'advertising', // 广告管理
  VIDEO_MANAGE = 'video_manger', // 视频管理
}

export default PurviewCodes
