


























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LdPopconfirm extends Vue {
    @Prop({ default: '' })
    title?: string

    @Prop({ default: '确定' })
    confirmButtonText?: string

    @Prop({ default: '取消' })
    cancelButtonText?: string

    @Prop({ default: 'el-icon-question' })
    icon?: string

    @Prop({ default: '#f90' })
    iconColor: string = '#f90'

    isVisible: boolean = false

    onCancel(): void {
        this.isVisible = false
        this.$emit('cancel')
    }

    onConfirm(): void {
        this.isVisible = false
        this.$emit('confirm')
    }
}
