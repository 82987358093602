import {
  // 社圈
  POSTERS,
  POSTERS_SHOW,
  POSTERS_PULL,
  SENSITIVES,
  SENSITIVES_LISTS,
  SENSITIVES_ENABLE,
  SENSITIVES_DISABLE,
  TOPICS,
  LOCATIONS,
  TOPICS_ENABLE,
  TOPICS_DISABLE,
  REPORTS,
  POST_FORBIDS,
  PUT_FORBIDS,
  ARTICLES,
  ARTICLES_PUSH,
  ARTICLES_PULL,
  ARTICLES_RECOMMENDS,
  TRENDS,
  TRENDS_REPLY,
  TRENDS_RECOMMENDS,
  TRENDS_REPORTS,
  REPORTS_USER_INFO,
} from '../urls'
import {
  Response
} from '../../interfaces'
import { axiosSoic } from '@/http/http'

// 社圈
/**
* 广告管理
*/
// 获取列表
function API_GET_POSTERS(params: any): Promise<Response> {
  return axiosSoic.get(POSTERS, {
    params
  })
}
// 创建广告
function API_POST_POSTERS(jwt_token: string,
  poster: {
    name: string
    content: string
    // area: string  // 广告地理位置描述 暂不做
    image_url: string
    source_path: string
    valid_start: string
    valid_end: string
    number: string
    position: string
  }): Promise<Response> {
  return axiosSoic.post(POSTERS, {
    jwt_token: jwt_token,
    poster
  })
}
// 更新修改广告
function API_PUT_POSTERS(id: number, jwt_token: string,
  poster: {
    name: string
    content: string
    // area: string  // 广告地理位置描述 暂不做
    image_url: string
    source_path: string
    valid_start: string
    valid_end: string
    number: string
    position: string
  }): Promise<Response> {
  return axiosSoic.put(`${POSTERS}/${id}`, {
    id,
    jwt_token: jwt_token,
    poster
  })
}
// 上架
function API_PUT_POSTERS_SHOW(id: number, jwt_token: string, params: {
  valid_start: string
  valid_end: string
}): Promise<Response> {
  return axiosSoic.put(POSTERS_SHOW.replace(/:id/, id.toString()), {
    jwt_token,
    valid_start: params.valid_start,
    valid_end: params.valid_end
  })
}
// 下架
function API_PUT_POSTERS_PULL(id: number, jwt_token: string): Promise<Response> {
  return axiosSoic.put(POSTERS_PULL.replace(/:id/, id.toString()), {
    jwt_token: jwt_token
  })
}
// 删除
function API_DEL_POSTERS(id: number, attrs: {
  jwt: string
}): Promise<Response> {
  return axiosSoic.delete(POSTERS + `/${id}`, {
    params: attrs
  })
}
/** 敏感词管理 */
// 敏感词列表
function API_GET_SENSITIVES(params: any): Promise<Response> {
  return axiosSoic.get(SENSITIVES, {
    params
  })
}
// 敏感词编码
function API_GET_SENSITIVES_LISTS(jwt_token: string): Promise<Response> {
  return axiosSoic.get(SENSITIVES_LISTS, {
    params: {
      jwt_token
    }
  })
}
// 新增敏感词
function API_POST_SENSITIVES(jwt_token: string,
  sensitive: {
    code: string
    content: string
  }): Promise<Response> {
  return axiosSoic.post(SENSITIVES, {
    jwt_token: jwt_token,
    sensitive
  })
}
// 编辑修改
function API_PUT_SENSITIVES(id: number, jwt_token: string,
  sensitive: {
    code: string
    content: string
  }): Promise<Response> {
  return axiosSoic.put(`${SENSITIVES}/${id}`, {
    id,
    jwt_token: jwt_token,
    sensitive
  })
}
// 删除
function API_DEL_SENSITIVES(id: number, attrs: {
  jwt: string
}): Promise<Response> {
  return axiosSoic.delete(SENSITIVES + `/${id}`, {
    params: attrs
  })
}
// 启用
function API_PUT_TOPICS_ENABLE(id: number, jwt_token: string): Promise<Response> {
  return axiosSoic.put(TOPICS_ENABLE.replace(/:id/, id.toString()), {
    jwt_token: jwt_token
  })
}
// 禁用
function API_PUT__TOPICS_DISABLE(id: number, jwt_token: string): Promise<Response> {
  return axiosSoic.put(TOPICS_DISABLE.replace(/:id/, id.toString()), {
    jwt_token: jwt_token
  })
}
// 话题管理
function API_GET_TOPICS(params: any): Promise<Response> {
  return axiosSoic.get(TOPICS, {
    params
  })
}
// 创建话题
function API_POST_TOPICS(jwt_token: string,
  topic: {
    name: string
    content: string
    thumbnail: string
    location_id: string
  }): Promise<Response> {
  return axiosSoic.post(TOPICS, {
    jwt_token: jwt_token,
    topic
  })
}
// 编辑话题
function API_PUT_TOPICS(id: number, jwt_token: string,
  topic: {
    name: string
    content: string
    thumbnail: string
    location_id: string
  }): Promise<Response> {
  return axiosSoic.put(`${TOPICS}/${id}`, {
    id,
    jwt_token: jwt_token,
    topic
  })
}
// 启用
function API_PUT_SENSITIVES_ENABLE(id: number, jwt_token: string): Promise<Response> {
  return axiosSoic.put(SENSITIVES_ENABLE.replace(/:id/, id.toString()), {
    jwt_token: jwt_token
  })
}
// 禁用
function API_PUT_SENSITIVES_DISABLE(id: number, jwt_token: string): Promise<Response> {
  return axiosSoic.put(SENSITIVES_DISABLE.replace(/:id/, id.toString()), {
    jwt_token: jwt_token
  })
}
// 删除
function API_DEL_TOPICS(id: number, attrs: {
  jwt_token: string
}): Promise<Response> {
  return axiosSoic.delete(TOPICS + `/${id}`, {
    params: attrs
  })
}
// 查询所属位置
function API_GET_LOCATIONS(attrs: {
  jwt_token: string
  code: string // A全部/1话题位置/2文章位置
}): Promise<Response> {
  return axiosSoic.get(LOCATIONS, {
    params: attrs
  })
}
// 新增位置
function API_POST_LOCATIONS(jwt_token: string,
  location: {
    number: string
    code: string // A全部/1话题位置/2文章位置
    name: string
  }): Promise<Response> {
  return axiosSoic.post(LOCATIONS, {
    jwt_token: jwt_token,
    location
  })
}
// 举报管理
function API_GET_REPORTS(params: any): Promise<Response> {
  return axiosSoic.get(REPORTS, {
    params
  })
}
function API_GET_REPORTS_INFO(params: any): Promise<Response> {
  return axiosSoic.get(REPORTS_USER_INFO, {
    params
  })
}
// 封号
function API_POST_FORBIDS(jwt_token: string, days: number,
  forbid: {
    user_id: string
    reason: string
  }): Promise<Response> {
  return axiosSoic.post(POST_FORBIDS, {
    days,
    jwt_token: jwt_token,
    forbid
  })
}
// 解封
function API_PUT_FORBIDS(id: number, jwt_token: string, reason: string): Promise<Response> {
  return axiosSoic.put(PUT_FORBIDS.replace(/:id/, id.toString()), {
    jwt_token: jwt_token,
    reason: reason
  })
}
// 文章管理
function API_GET_ARTICLES(params: any): Promise<Response> {
  return axiosSoic.get(ARTICLES, {
    params
  })
}
// 查询单条文章
function API_ONE_ARTICLES(id: string, attrs: {
  jwt_token: string
}): Promise<Response> {
  return axiosSoic.get(ARTICLES + `/${id}`, {
    params: attrs
  })
}
// 创建文章
function API_POST_ARTICLES(jwt_token: string,
  article: {
    title: string
    html: string
    position: string
    thumbnail_url: string
    // 新增参数字段
    creater_name?: string
    creater_image?: string
    platform?: string
    channel_code: number
    release_at?: string
    tag_text?: string
  }): Promise<Response> {
  return axiosSoic.post(ARTICLES, {
    jwt_token: jwt_token,
    article
  })
}
// 修改文章
function API_PUT_ARTICLES(id: number, jwt_token: string,
  article: {
    title: string
    html: string
    position: string
    thumbnail_url: string
  }): Promise<Response> {
  return axiosSoic.put(`${ARTICLES}/${id}`, {
    id,
    jwt_token: jwt_token,
    article
  })
}
// 删除
function API_DEL_ARTICLES(id: number, attrs: {
  jwt_token: string
}): Promise<Response> {
  return axiosSoic.delete(ARTICLES + `/${id}`, {
    params: attrs
  })
}
// 启用
function API_PUT_ARTICLES_PUSH(id: number, jwt_token: string): Promise<Response> {
  return axiosSoic.put(ARTICLES_PUSH.replace(/:id/, id.toString()), {
    jwt_token: jwt_token
  })
}
// 停用
function API_PUT_ARTICLES_PULL(id: number, jwt_token: string): Promise<Response> {
  return axiosSoic.put(ARTICLES_PULL.replace(/:id/, id.toString()), {
    jwt_token: jwt_token
  })
}
// 文章推荐
function API_POST_ARTICLES_RECOMMENDS(id: number, jwt_token: string, recommend: {
  person_crow: string
  content_level: string

}): Promise<Response> {
  return axiosSoic.post(ARTICLES_RECOMMENDS.replace(/:id/, id.toString()), {
    jwt_token: jwt_token,
    recommend
  })
}
// 动态管理
function API_GET_TRENDS(params: any): Promise<Response> {
  return axiosSoic.get(TRENDS, {
    params
  })
}
// 新增动态
function API_POST_TRENDS(jwt_token: string,
  topic_ids: string,
  trend: {
    title: string
    content: string
    person_crow: string
    image_urls: string
    thumbnail: string
  }): Promise<Response> {
  return axiosSoic.post(TRENDS, {
    jwt_token: jwt_token,
    topic_ids,
    trend
  })
}
// 查询单条动态
function API_ONE_TRENDS(id: string, attrs: {
  jwt_token: string
}): Promise<Response> {
  return axiosSoic.get(TRENDS + `/${id}`, {
    params: attrs
  })
}
// 审核通过/不通过
function API_AGREE_TRENDS_REPLY(ids: string, jwt_token: string,
  agree: string, reason?: string): Promise<Response> {
  return axiosSoic.put(TRENDS_REPLY.replace(/:ids/, ids), {
    jwt_token: jwt_token,
    agree: agree,
    reason: reason
  })
}
// 动态推荐
function API_POST_TRENDS_RECOMMENDS(id: number, jwt_token: string, recommend: {
  person_crow: string
  content_level: string

}): Promise<Response> {
  return axiosSoic.post(TRENDS_RECOMMENDS.replace(/:id/, id.toString()), {
    jwt_token: jwt_token,
    recommend
  })
}
// 删除
function API_DEL_TRENDS(id: number, attrs: {
  jwt_token: string
}): Promise<Response> {
  return axiosSoic.delete(TRENDS + `/${id}`, {
    params: attrs
  })
}
// 查询被举报原因
function API_REPORT_TRENDS(id: string, attrs: {
  jwt_token: string
}): Promise<Response> {
  return axiosSoic.get(TRENDS_REPORTS.replace(/:id/, id.toString()), {
    params: attrs
  })
}
// 审核爬虫文章
function API_POST_ARTICLES_NEW(jwt_token: string,
  article: {
    title: string
    html: string
    position: string
    thumbnail_url: string
    // 新增参数字段
    creater_name?: string
    creater_image?: string
    platform?: string
    channel_code: number
    release_at?: string
    tag_text?: string
  }): Promise<Response> {
  return axiosSoic.post(ARTICLES + '/new', {
    jwt_token: jwt_token,
    article
  })
}

export {
  // 社圈
  // 广告管理列表
  API_GET_POSTERS,
  // 创建广告
  API_POST_POSTERS,
  // 更新广告
  API_PUT_POSTERS,
  // 删除广告
  API_DEL_POSTERS,
  // 上架广告
  API_PUT_POSTERS_SHOW,
  // 下架广告
  API_PUT_POSTERS_PULL,
  // 敏感词管理
  API_GET_SENSITIVES,
  API_GET_SENSITIVES_LISTS,
  API_POST_SENSITIVES,
  API_DEL_SENSITIVES,
  API_PUT_SENSITIVES,
  API_PUT_SENSITIVES_ENABLE,
  API_PUT_SENSITIVES_DISABLE,
  // 话题管理
  API_GET_TOPICS,
  API_POST_TOPICS,
  API_PUT_TOPICS,
  API_DEL_TOPICS,
  API_PUT__TOPICS_DISABLE,
  API_PUT_TOPICS_ENABLE,
  API_GET_LOCATIONS, // 所属位置查询
  API_POST_LOCATIONS, // 添加所属位置
  // 举报管理
  API_GET_REPORTS,
  API_POST_FORBIDS, // 封号
  API_PUT_FORBIDS, // 解封
  // 文章管理
  API_GET_ARTICLES,
  API_ONE_ARTICLES,
  API_POST_ARTICLES,
  API_DEL_ARTICLES,
  API_PUT_ARTICLES,
  API_PUT_ARTICLES_PUSH,
  API_PUT_ARTICLES_PULL,
  API_POST_ARTICLES_RECOMMENDS,
  API_POST_ARTICLES_NEW,
  // 动态管理
  API_GET_TRENDS,
  API_POST_TRENDS,
  API_ONE_TRENDS,
  API_AGREE_TRENDS_REPLY,
  API_DEL_TRENDS,
  API_POST_TRENDS_RECOMMENDS,
  API_REPORT_TRENDS,
  API_GET_REPORTS_INFO,
}
