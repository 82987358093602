import {
  // 最新API, 其他的等待删掉
  ARTICLE,
  AUTO_RESERVATION,
} from '../urls'
import {
  Response
} from '../../interfaces'
import { axiosHlc } from '@/http/http'
import { getLoginInfo } from '@/utils/login-management'

// 查询文章
function API_GET_ARTICLE(params: any): Promise<Response> {
  return axiosHlc.get(ARTICLE, {
    params
  })
}
function API_POST_ARTICLE(params: any): Promise<Response> {
  return axiosHlc.post(ARTICLE, Object.assign({
    'jwt_token': getLoginInfo(),
  }, params))
}
function API_PUT_ARTICLE(id: any, params: any): Promise<Response> {
  return axiosHlc.put(`${ARTICLE}/${id}`, Object.assign({
    'jwt_token': getLoginInfo(),
  }, params))
}
function API_DEL_ARTICLE(id: any): Promise<Response> {
  return axiosHlc.delete(`${ARTICLE}/${id}`, {
    params: {
      'jwt_token': getLoginInfo()
    }
  })
}

/**
* 查询自主预约数据
*/
function API_GET_AUTO_RESERVATION(attrs: {
  jwt: string
  search: string
  status: string
  reservation_time: string
  area: string
}): Promise<Response> {
  return axiosHlc.get(AUTO_RESERVATION, {
    params: attrs
  })
}

export {
  // 文章
  API_GET_ARTICLE,
  API_POST_ARTICLE,
  API_PUT_ARTICLE,
  API_DEL_ARTICLE,
  // 查询自主预约数据
  API_GET_AUTO_RESERVATION,
}
