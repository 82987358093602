// common
const Login = () => import(/* webpackChunkName: "common" */ '../views/login/Login.vue')
const NoPermissions = () => import(/* webpackChunkName: "common" */ '../views/components/NoPermissions.vue')
const DynamicHome = () => import(/* webpackChunkName: "common" */ '../views/DynamicHome.vue')
const Entry = () => import(/* webpackChunkName: "common" */ '../views/components/EntryPage.vue')
const SimpleHeader = () => import(/* webpackChunkName: "common" */ '../views/components/SimpleHeader.vue')
const Copyright = () => import(/* webpackChunkName: "common" */ '../views/components/Copyright.vue')
const NotFound = () => import(/* webpackChunkName: "common" */ '../views/error/404.vue')
const CommonIndex = () => import(/* webpackChunkName: "common" */ '../views/common/Index.vue')

// home
const Home = () => import(/* webpackChunkName: "common" */ '../views/Home.vue')
const HomeSideMenu = () => import(/* webpackChunkName: "common" */'../views/home/SideMenu.vue')
const HomeHospital = () => import(/* webpackChunkName: "common" */'../views/home/community-hospital/CommunityHospital.vue')
const HomeAlliance = () => import(/* webpackChunkName: "common" */'../views/home/medicine-alliance/ChineseMedicineAlliance.vue')
const HomeHealth = () => import(/* webpackChunkName: "common" */'../views/home/health-home/HealthHome.vue')
const HomeReservation = () => import(/* webpackChunkName: "common" */'../views/home/auto-reservation/AutoReservation.vue')

// community
const CommunitySideMenu = () => import(/* webpackChunkName: "community" */'../views/community/SideMenu.vue')
const CommunityAdvert = () => import(/* webpackChunkName: "community" */'../views/community/advert/Advert.vue')
const CommunityTrends = () => import(/* webpackChunkName: "community" */'../views/community/Trends.vue')
const CommunityReport = () => import(/* webpackChunkName: "community" */'../views/community/Report.vue')
const CommunityArticle = () => import(/* webpackChunkName: "community" */'../views/community/article/Article.vue')
const CommunityTopic = () => import(/* webpackChunkName: "community" */'../views/community/topic/Topic.vue')
const CommunitySensitive = () => import(/* webpackChunkName: "community" */'../views/community/Sensitive.vue')
const CommunitySpider = () => import(/* webpackChunkName: "community" */'../views/community/spider/Spider.vue')
const CommunityLog = () => import(/* webpackChunkName: "community" */'../views/community/Log.vue')

// system management
const SystemManagementSidemenu = () => import(/* webpackChunkName: "system" */'../views/system-management/SideMenu.vue')
const SystemManagementUserManagement = () => import(/* webpackChunkName: "system" */'../views/system-management/user-management/Index.vue')
const SystemManagementOrgDetails = () => import(/* webpackChunkName: "system" */'../views/system-management/user-management/org-details/Index.vue')
const SystemManagementUserPurview = () => import(/* webpackChunkName: "system" */'../views/system-management/user-purview/Index.vue')
const SystemManagementAppSettings = () => import(/* webpackChunkName: "system" */'../views/system-management/app-settings/Index.vue')
const SystemManagementLog = () => import(/* webpackChunkName: "system" */'../views/system-management/log/Index.vue')

// Advertising VideoManager
const AdvertisingSideMenu = () => import(/* webpackChunkName: "Advertising" */'../views/advertising/SideMenu.vue')
const AdvertisingVideoManager = () => import(/* webpackChunkName: "Advertising" */'../views/advertising/VideoManager.vue')
const AdvertisingVideoClassify = () => import(/* webpackChunkName: "Advertising" */'../views/advertising/VideoClassify.vue')
export default {
    Login,
    NoPermissions,
    DynamicHome,
    Entry,
    SimpleHeader,
    Copyright,
    NotFound,
    CommonIndex,

    Home,
    HomeSideMenu,
    HomeHospital,
    HomeAlliance,
    HomeHealth,
    HomeReservation,

    CommunitySideMenu,
    CommunityAdvert,
    CommunityTrends,
    CommunityReport,
    CommunityArticle,
    CommunityTopic,
    CommunitySensitive,
    CommunitySpider,
    CommunityLog,

    SystemManagementSidemenu,
    SystemManagementUserManagement,
    SystemManagementOrgDetails,
    SystemManagementUserPurview,
    SystemManagementAppSettings,
    SystemManagementLog,

    AdvertisingSideMenu,
    AdvertisingVideoManager,
    AdvertisingVideoClassify
}
