import axios from 'axios'
import {
  ErrInterface,
  Response
} from '../interfaces'

// 响应拦截器
axios.interceptors.response.use(
  response => response,
  (err: ErrInterface) => {
    let response: Response = err ? err.response ? err.response : { status: 900, data: {} } : { status: 900, data: {} }
    // 请求失败时，即status!=200
    if (err && err.response) {
      switch (response.status) {
        case 401:
          response.message = '未授权，请重新登录'
          break
        case 403:
          break
        case 404:
          response.message = '请求错误,未找到该资源'
          break
        case 500:
          response.message = '服务器端出错'
          break
        default:
          response.message = `连接错误`
      }
    } else {
      response.message = '当前网络状态不佳'
    }
    return Promise.resolve(response) // 改成这样都需要用result.status === 200 来判断是否请求成功
  }
)
