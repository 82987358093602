import Vue from 'vue'
import Vuex from 'vuex'
import {
  setLoginInfo
} from '../utils/login-management'
import Commands, { ActionCommands } from './commands'
import { API_GET_USER_BY_JWT } from '@/http/apis'
import { RequestCode } from '@/utils/codes'

Vue.use(Vuex)

interface _State {
  user: any
  purviews: Array<any>
  entryCount: number
}

const getPermissions = (roles: Array<{ powers: Array<{ code: string }> }>) => {
  // 所有权限
  const permissions = (roles || []).map(r => r.powers)
  // 权限编码
  const permissionCodes = permissions.flat().map(per => per.code)
  // 去重
  return Array.from(new Set(permissionCodes))
}

export default new Vuex.Store({
  state: {
    entryCount: 0,
    user: {
      nickname: '',
      id: null,
      jwt: '',
      phone: '',
      name: '',
      uuid: '',
      idno: '',
      birthday: '',
      status: '',
      created_at: '',
      head_url: '',
      login_at: '',
      login_ip: '',
      roles: [],
      sex: '',
      updated_at: '',
    },
    purviews: [],
  },
  mutations: {
    [Commands.setUserInfo](state: _State, user: any) {
      state.user = user
      setLoginInfo(user.jwt)
    },
    [Commands.setPurviews](state: _State, permissions: any) {
      state.purviews = permissions
    },
    [Commands.SetUserInfoSuger](state: _State, user: any) {
      state.user = {
        nickname: user.nickname,
        id: user.id,
        jwt: user.jwt,
        phone: user.phone,
        name: user.name,
        uuid: user.uuid,
        idno: user.idno,
        birthday: user.birthday,
        status: user.status,
        created_at: user.created_at,
        head_url: user.head_url,
        login_at: user.login_at,
        login_ip: user.login_ip,
        roles: user.roles,
        sex: user.sex,
        updated_at: user.updated_at,
      }
      state.purviews = getPermissions(user.roles)
      setLoginInfo(user.jwt)
    },
    [Commands.SetEntryCount](state: _State, n: number) {
      state.entryCount = n
    }
  },
  actions: {
    async [ActionCommands.getPurviews]({ commit }) {
      const result = await API_GET_USER_BY_JWT()
      if (result.status >= 200 && result.status < 300) {
        const data = result.data
        if (data.code === RequestCode.success) {
          const info = data.data
          commit(Commands.setUserInfo, info)
          const distinctPermissions = getPermissions(info.roles)
          commit(Commands.setPurviews, distinctPermissions)
          return Promise.resolve(distinctPermissions)
        }
        return Promise.resolve([])
      }
      return Promise.reject(new Error('没有获取到权限'))
    }
  }
})
