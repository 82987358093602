import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import { isLogin } from '../utils/login-management'
import customConfig from '../custom-config'
import {
  LoginPath,
  PageNotFoundPath,
  NoPermissionsPath,
  // name
  LoginName,
  MutativeName,
  NoPermissionsName,
  Mutative,
  Home,
  Community,
  SystemManagement,
  Static,
  Advertising
} from './constants'
import store from '../store/index'
import commands, { ActionCommands } from '@/store/commands'
import PurviewCodes from '@/utils/purview-codes'
import Pages from './pages'

Vue.use(VueRouter)

// 处理重复点击同一菜单,发生重复跳转会报错
const originalPush: any = VueRouter.prototype.push
VueRouter.prototype.push = function push(location: any) {
  return originalPush.call(this, location).catch((err: any) => err)
}

const routes = [
  {
    path: LoginPath,
    name: LoginName,
    component: Pages.Login
  },
  {
    path: NoPermissionsPath,
    name: NoPermissionsName,
    component: Pages.NoPermissions
  },
  {
    path: customConfig.basePath + Mutative,
    component: Pages.DynamicHome,
    children: [
      {
        path: '/',
        name: MutativeName,
        components: {
          default: Pages.Entry,
          header: Pages.SimpleHeader,
          footer: Pages.Copyright
        }
      }, // 入口页面
    ],
  },
]

const router = new VueRouter({
  mode: customConfig.routeMode,
  routes
})

const route404 = [{
  path: PageNotFoundPath,
  component: Pages.NotFound
}]

const dynamicRoutesList = [
  {
    path: customConfig.basePath,
    component: Pages.Home,
    children: [
      {
        path: '/',
        redirect: Home
      },
      {
        path: Home,
        components: {
          default: Pages.CommonIndex,
          sidemenu: Pages.HomeSideMenu
        },
        meta: {
          permissions: [PurviewCodes.MENU_HOME],
        },
        children: [
          {
            path: '/',
            redirect: 'commHospital',
          }, {
            path: 'commHospital',
            component: Pages.HomeHospital,
            meta: {
              permissions: [PurviewCodes.MENU_HOME_HOSPITAL]
            }
          }, {
            path: 'chineseAlliance',
            component: Pages.HomeAlliance,
            meta: {
              permissions: [PurviewCodes.MENU_HOME_HOSPITAL]
            }
          }, {
            path: 'healthHome',
            component: Pages.HomeHealth,
            meta: {
              permissions: [PurviewCodes.MENU_HOME_HOSPITAL]
            }
          }, {
            path: 'autoReservation',
            component: Pages.HomeReservation,
            meta: {
              permissions: [PurviewCodes.MENU_HOME_INDEPENDENTSIGNING]
            }
          },
        ]
      }, // 首页
      {
        path: Community,
        components: {
          default: Pages.CommonIndex,
          sidemenu: Pages.CommunitySideMenu
        },
        meta: {
          permissions: [PurviewCodes.MENU_SOCIAL],
        },
        children: [
          {
            path: '/',
            redirect: 'advert'
          }, {
            path: 'advert',
            component: Pages.CommunityAdvert,
            meta: {
              permissions: [PurviewCodes.MENU_SOCIAL_AD],
            }
          }, {
            path: 'trends',
            name: 'TrendsPage',
            component: Pages.CommunityTrends,
            meta: {
              permissions: [PurviewCodes.MENU_SOCIAL_TREND],
            }
          }, {
            path: 'report',
            component: Pages.CommunityReport,
            meta: {
              permissions: [PurviewCodes.MENU_SOCIAL_REPORT],
            }
          }, {
            path: 'article',
            component: Pages.CommunityArticle,
            meta: {
              permissions: [PurviewCodes.MENU_SOCIAL_ARTICLE],
            }
          }, {
            path: 'topic',
            component: Pages.CommunityTopic,
            meta: {
              permissions: [PurviewCodes.MENU_SOCIAL_TOPIC],
            }
          }, {
            path: 'sensitive',
            component: Pages.CommunitySensitive,
            meta: {
              permissions: [PurviewCodes.MENU_SOCIAL_WORDS],
            }
          }, {
            path: 'spider',
            component: Pages.CommunitySpider,
          }, {
            path: 'log',
            component: Pages.CommunityLog,
          }
        ]
      }, // 社圈
      {
        path: SystemManagement,
        components: {
          default: Pages.CommonIndex,
          sidemenu: Pages.SystemManagementSidemenu,
        },
        meta: {
          permissions: [PurviewCodes.MENU_SYSTEM],
        },
        children: [
          {
            path: '/',
            redirect: 'user-management'
          },
          {
            path: 'user-management',
            component: Pages.SystemManagementUserManagement,
            meta: {
              permissions: [PurviewCodes.MENU_SYSTEM_CUSTOMER]
            }
          }, {
            path: 'org-details',
            component: Pages.SystemManagementOrgDetails,
          }, {
            path: 'user-purview',
            component: Pages.SystemManagementUserPurview,
            meta: {
              permissions: [PurviewCodes.MENU_SYSTEM_EMPLOYEE, PurviewCodes.MENU_SYSTEM_DEPARTMENT, PurviewCodes.MENU_SYSTEM_ROLE]
            }
          }, {
            path: 'app-settings',
            component: Pages.SystemManagementAppSettings,
          }, {
            path: 'app-versions',
            component: () => import(/* webpackChunkName: "data" */'../views/system-management/app-versions/Index.vue'),
            meta: {
              permissions: [PurviewCodes.MENU_SYSTEM_VERSION]
            }
          }, {
            path: 'log',
            component: Pages.SystemManagementLog,
          }
        ]
      }, // 系统管理
      {
        path: Advertising,
        components: {
          default: Pages.CommonIndex,
          sidemenu: Pages.AdvertisingSideMenu
        },
        meta: {
          permissions: [PurviewCodes.MENU_SYSTEM],
        },
        children: [
          {
            path: '/',
            redirect: 'video_manger'
          },
          {
            path: 'video_manger',
            component: Pages.AdvertisingVideoManager,
          },
          {
            path: 'video_classify',
            component: Pages.AdvertisingVideoClassify,
          }
        ]
      }, // 广告管理
      { // 用来显示非动态路由页面
        path: Static,
        component: Pages.CommonIndex,
      },
    ]
  }, // root
]

/**
 * 根据动态路由meta里面设置的权限，控制动态路由的生成
 *
 * @param purviews 权限集合
 * @param routes 动态路由集合
 */
function routesMatch(purviews: Array<any>, routes: Array<any>) {
  const match = (routeAry: Array<any>): Array<any> => {
    const result: Array<any> = []
    routeAry.forEach((route: any) => {
      let isVisited = false
      if (route.meta && route.meta.permissions && route.meta.permissions.length) {
        route.meta.permissions = route.meta.permissions.filter((permission: any) => purviews.includes(permission))
        isVisited = true
      }
      if (route.children && route.children.length) {
        const res = match(route.children)
        route.children = res
        // 修正子级的redirect
        const redirects = route.children.filter((child: any) => child.redirect)
        if (redirects.length) {
          const redirectPath = redirects[0].redirect
          const hasMatchRedirect = route.children.some((child: any) => child.path === redirectPath)
          if (!hasMatchRedirect) {
            for (let i = 0; i < route.children.length; i++) {
              if (route.children[i].path !== '/') {
                redirects[0].redirect = route.children[i].path
                break
              }
            }
          }
        }

        const realChildren = route.children.filter((child: any) => child.path !== '/')

        // 如果没有子级菜单，那么父级也不需要了
        if (!res.length || !realChildren.length) {
          return
        }
      }
      if (isVisited) {
        if (route.meta.permissions.length) {
          result.push(route)
        }
      } else {
        result.push(route)
      }
    })
    return result
  }

  return match(routes)
}

let count = 1

router.beforeEach((to: Route, from: Route, next: Function) => {
  if (isLogin()) {
    count++
    // 没有获取到权限的时候
    if (!store.state.purviews || !store.state.purviews.length) {
      // 三次获取不到，去没有权限页面
      if (count >= 3) {
        if (to.name === NoPermissionsName) {
          return next()
        }
        return next({ name: NoPermissionsName })
      }

      store
        .dispatch(ActionCommands.getPurviews)
        .then((purviews: Array<any>) => {
          if (!purviews.length) {
            return next({ name: NoPermissionsName })
          }

          // 动态添加路由
          const routes = routesMatch(purviews, dynamicRoutesList)
          if (routes.length) {
            router.addRoutes(routes)
          }
          router.addRoutes(route404)
          count = 1
          next(to.path)
        })
        .catch((_: any) => next({ name: NoPermissionsName }))
      return
    }

    // 获取到权限
    // 避免死循环
    if (to.path === from.path) {
      return next({ name: MutativeName })
    }
    // 如果是去以下页面，从哪里来回那里去
    if (to.name === NoPermissionsName || to.name === LoginName) {
      return next(from.path)
    }

    if (to.name === MutativeName) {
      return next()
    }

    store.commit(commands.SetEntryCount, 1)
    return next()
  } else {
    count = 1
    if (to.name !== LoginName) {
      return next({ name: LoginName })
    }
    next()
  }
})

export default router
