import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './utils/axios-config'
import './utils/toFixed-patch'
import '@/scss/common.scss'
import 'element-ui/lib/theme-chalk/icon.css'
import ElementConfig from './element-config'
// 引入element自定义主题
import '@/scss/element-variables.scss'
import VueExtend from './utils/vueExtends'
// 自定义组件
import LdPopconfirm from './views/common/LdPopconfirm'
import { Response as MyResponse } from './interfaces'
import VueClipboard from 'vue-clipboard2'

// Vue.prototype.$ELEMENT = { zIndex: 2010 }
// Vue.use(Element, { zIndex: 2010 })
Vue.use(ElementConfig)
Vue.use(LdPopconfirm)
Vue.use(VueClipboard)

declare module 'vue/types/vue' {
  type succCb = (this: Vue, result: MyResponse, cb: (data: any) => any, config?: { error: boolean, warn: boolean }) => any

  interface Vue {
    $normalizeStringForRegexp: (str: string) => string
    $printArea: (html: string) => void
    $successCb: succCb
  }
}

Vue.config.productionTip = false

VueExtend()

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
