import customConfig from '../custom-config'

// 组件路径
export const Mutative = 'mutative'
export const Home = 'home'
export const Community = 'community'
export const SystemManagement = 'system-management'
export const Static = 'static'
export const Advertising = 'advertising'

// 路径常量
export const LoginPath = customConfig.basePath + 'login'
export const PageNotFoundPath = customConfig.basePath + '*'
export const NoPermissionsPath = customConfig.basePath + 'nopermissions'

// 组件name常量
export const LoginName = 'login'
export const MutativeName = `${Mutative} name`
export const NoPermissionsName = 'no permissions name'
